define("nadex-web-app/translations/en-us", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "account": {
      "account": "account",
      "account-balance": "AVLB",
      "close": "Close",
      "demo": "Demo",
      "entity": "Master",
      "entity-trader": "Trader",
      "exchange-time": "Exchange Time",
      "live": "Live",
      "open-live-account": "Open Live Account",
      "platform-status": {
        "checking": "Checking...",
        "degraded": "Degraded",
        "operational": "Operational",
        "title": "Platform Status"
      },
      "pnl": "P&L",
      "time": "Exchange Time",
      "title-logged-in": "Nadex Platform",
      "title-logged-out": "Nadex Platform Login"
    },
    "account-expiry": {
      "demo-account-expired": "Your demo account expired.",
      "demo-account-expiring": "Your demo account expires {daysToExpire, plural, =0 {<span class='account-expiry_remaining'>today</span>}  =1 {in <span class='account-expiry_remaining'>1 day</span>} other {in <span class='account-expiry_remaining'># days</span>}}.",
      "extend-account": "To extend your demo account, please follow these steps below:",
      "extend-demo-forever": "And that’s it, we will extend your demo account forever!",
      "have-a-live-account": "Already have a live account?",
      "hi-client": "Hi {name}!",
      "keep-forever": "Keep forever?",
      "login": "Login",
      "ok": "OK, got it",
      "open-live-account": "Open live account",
      "reactivate": "To reactivate your demo account, please follow these steps below:",
      "reactivate-step-1": "Open a live account",
      "reactivate-step-2": "Fund your live account",
      "reactivate-step-3": "Email"
    },
    "address": {
      "address": "Address",
      "city": "City",
      "country": "Country",
      "done": "Done",
      "edit": "Edit",
      "errors": {
        "line1": "Please enter a valid address",
        "postCode": "Please enter a valid zip code",
        "town": "Please enter your city"
      },
      "mailing-address": "Mailing Address",
      "residential-address": "Residential Address",
      "same-address": "My residential address is also my mailing address",
      "state": "State/Region",
      "zip": "Zip Code"
    },
    "alerts": {
      "reload-app": "Reload to update",
      "update-available": "A new version is available."
    },
    "app-name": "Nadex",
    "assets": {
      "assets": "Markets",
      "binary": "Binary <br />Options",
      "bracket": "Knock-<br />Outs",
      "browse": "Browse",
      "search": "Search",
      "spread": "Call <br />Spreads",
      "test": "Test <br />Markets",
      "watchlists": "Watchlists"
    },
    "balances": {
      "abbrev-accountValue": "Acc. value",
      "abbrev-available": "Available",
      "abbrev-cumulativePositionsValue": "Pos. Value",
      "accountValue": "Account value",
      "accountValue-details": "Total value of your account (Available + Reserved + Position Value.)",
      "available": "Available Balance",
      "available-details": "Funds available to deal or withdraw.",
      "close": "Close",
      "cumulativeOrdersValue": "Reserved",
      "cumulativeOrdersValue-details": "Funds reserved for working orders, including fees.",
      "cumulativePnl": "Total P&L",
      "cumulativePnl-details": "Profit or Loss from all current open positions.",
      "cumulativePositionsValue": "Position Value",
      "cumulativePositionsValue-details": "Total value of all current open positions.",
      "hide-values": "Hide values",
      "select-balances": "Select balances"
    },
    "browse": {
      "bracket": {
        "commodities": "Gold, Oil, Corn",
        "forex": "EUR/USD, GBP/USD, USD/JPY",
        "indices": "Wall Street, US Tech"
      },
      "check-back": "Check back at {time} EST on {date} to see new {assetType} Contracts.",
      "classes": "By Market Classes",
      "commodities": "Gold, Silver, Oil",
      "competition-tooltip-learn": "Learn more",
      "competition-tooltip-message": "Test your trading strategies and compete with other traders for a chance to win $5,000*",
      "competition-tooltip-registration": "{days, plural, =0 { Registration closes today. Don’t miss your chance to win $5,000*! } =1 { Registration closes in 1 day. Don’t miss your chance to win $5,000*! } other {Registration is open now. Test your skills for a chance to win $5,000*!} }",
      "competition-tooltip-sign-up": "Sign up",
      "competition-tooltip-terms": "* Terms and Conditions apply",
      "competition-tooltip-title": "Trading Competition",
      "cryptocurrency": "Bitcoin",
      "discover-brackets-1": "Learn more",
      "discover-brackets-2": "about knock-outs",
      "duration": "By Duration",
      "event-tooltip-message": "Trade the event, not the market reaction. Turn your opinion into a trade.",
      "event-tooltip-title": "Economic Events",
      "events": "Jobless Claims, Nonfarm Payrolls",
      "forex": "EUR/USD, USD/ JPY, AUD/USD",
      "hide": "Hide",
      "indices": "Wall Street, US Tech, Germany 40",
      "live": "Live",
      "new": "New",
      "refresh": "Refresh"
    },
    "closed": {
      "body": "You have no trade history for the last 24 hours",
      "date": "Date",
      "date-time": "Date/Time",
      "header": "No history",
      "result": "Result",
      "time": "Time"
    },
    "contact": {
      "email": "Email us",
      "email-compliance": "Compliance",
      "email-general": "General",
      "introduction": "We’re here to help if you have any questions.",
      "office": "Write to us",
      "view": "View Map"
    },
    "count-down": {
      "abbreviated": {
        "day": "{day}d",
        "day-with-hours": "{day}d {hours}h",
        "days": "{days}d",
        "days-with-hours": "{days}d {hours}h",
        "hours": "{hours}h {minutes}m",
        "minutes": "{minutes}m {seconds}s",
        "seconds": "{seconds}s"
      },
      "full": {
        "day": "{day} day",
        "day-with-hours": "{day} day {hours} hours",
        "days": "{days} days",
        "days-with-hours": "{days} days {hours} hours",
        "hours": "{hours} hours {minutes} minutes",
        "minutes": "{minutes} minute {seconds} seconds",
        "seconds": "{seconds} seconds"
      }
    },
    "dash": "-",
    "docUpload": {
      "accepted": "Approved",
      "accepted-1": "If you can't provide any of these documents, please email us at ",
      "accepted-documents": "Accepted documents",
      "accepted-files": "We accept PDF, JPEG or PNG files up to 20MB in size",
      "address": "Your address",
      "address-details": {
        "address": "Your residential Address",
        "bill": "Utility bill",
        "bill-explain": "Less than 3 months old, no past due balances",
        "bill-short": "document",
        "check-bill": "Check your utility bill details",
        "check-lease": "Check your lease agreement details",
        "check-license": "Check your license details",
        "intro": "Make sure your document shows the right address.",
        "lease": "Lease agreement",
        "lease-document": "Your lease agreement document",
        "lease-explain": "Showing your signature and lease terms",
        "lease-short": "document",
        "license": "Driver's license",
        "license-explain": "Unexpired",
        "license-short": "license",
        "modal-text-1": "If you have any questions, please email",
        "modal-title": "Update your address",
        "other-lease-page": "Please upload a copy of the other page of your lease agreement.",
        "update-address": "Update Address",
        "update-address-below": "You can update your address using the link below.",
        "update-address-desktop-text-1": "You can update your address by ",
        "update-address-desktop-text-2": "clicking here",
        "wrong": "Wrong details?"
      },
      "address-explain": "This document can also be used to prove your address. Does your {type} include your current residential address?",
      "address-title": "Address on your {type}",
      "another-document": "Do you need to upload another page?",
      "back-to-account": "Back to account",
      "camera": "Take a photo",
      "camera-guidelines": "The document should be within the space above and in focus",
      "check": "These should match the details on your account.",
      "check-address": "Confirm Address",
      "check-image": "Confirm Image",
      "check-instructions": "Please check that the document is fully visible, in focus and free from glare.",
      "check-signature": "Does this page include your signature agreeing to the lease terms?",
      "close": "Close",
      "confirm": "Upload",
      "declaration": "Please confirm the {type, select, bill {bill} id {ID card shows your} lease {document shows} ssn {card shows your} other { {type} shows your} }:",
      "document-must-show": "Your document must show:",
      "document-rejected": "Sorry, we were unable to use one of your documents",
      "done": "Done",
      "email-address": "accountopening@nadex.com",
      "explanation": "You'll need to verify your details to start trading.",
      "identity": "Your name & date of birth",
      "identity-details": {
        "bill": "utility bill",
        "check-id": "Check your ID card details",
        "check-license": "Check your license details",
        "check-passport": "Check your passport details",
        "id": "National ID card",
        "id-explain": "Unexpired",
        "id-short": "ID card",
        "id-title": "ID card",
        "intro": "The document will need to match the details below provided when opening your account:",
        "lease": "lease",
        "license": "Driver's license",
        "license-explain": "Unexpired",
        "license-short": "license",
        "license-title": "driver's license",
        "modal-text": "If your name is incorrect, please email our account opening team on the following email <a href='mailto:accountopening@nadex.com' target='_top'>accountopening@nadex.com</a>",
        "modal-title": "Your personal details",
        "name": "Account holder's name",
        "passport": "Passport",
        "passport-explain": "Unexpired",
        "passport-short": "passport",
        "passport-title": "passport"
      },
      "intro": "We just need a few documents to verify the details below.",
      "later": "VIEW LATER",
      "library": "Pick from library",
      "manage-data": "Find out how we manage you data in our",
      "missing": "Upload missing",
      "my-address": "Current residential address",
      "my-dob": "Date of birth",
      "my-lease-terms": "Signature to lease terms",
      "my-name": "Legal name",
      "my-ssn": "Social Security Number (SSN)",
      "neither-document": "Don't have either of these documents?",
      "next-document": "Next document",
      "no": "No",
      "no-documents": "Don't have any of these documents?",
      "no-documents-help-1": "If you can't provide any of these documents, please email us at <a href='mailto:accountopening@nadex.com' class='tooltip_link' target='_top'>accountopening@nadex.com</a>",
      "no-documents-help-2": "or talk to us free of charge on",
      "no-due-balances": "Has no past due balances",
      "now": "VERIFY NOW",
      "ok": "OK, got it",
      "pending": "Under Review",
      "pending-explanation": "Your document has been received and we aim to review it within 24 hours.",
      "privacy": "Privacy Policy",
      "process-time": "We normally process documents within 24 hours.",
      "reject": {
        "steps-1": "We've sent you an email with details of what to do next.",
        "steps-2": "Email us if you have any questions: ",
        "title": "We were unable to use this document",
        "try-again": "try another document"
      },
      "rejected": "Rejected",
      "retake": "Retake",
      "review": "Review Information",
      "review-confirmation": "We’ll review it within 24 hours and email you as soon as this is done.",
      "review-photo": {
        "title": "Review Photo",
        "title-signature": "Lease terms and signature"
      },
      "safe-and-secure": "We'll keep your personal data safe and secure.",
      "secure": "We will keep your data safe and secure – find out more in our ",
      "secure-short": "Find out how we manage your data in our",
      "see-details": "SEE DETAILS",
      "select-file": "Select a file to upload",
      "send-documents-to": "Please check your e-mail and send your documents to ",
      "shows-address": "Shows your current residential address",
      "ssn": "Your SSN or ITIN",
      "ssn-alternate": "SSN or ITIN card",
      "ssn-details": {
        "card": "card",
        "check-ssn": "Check your SSN or ITIN details",
        "intro": "The document will need to match the details below provided when opening your account:",
        "medicare": "Medicare Card",
        "ssn": "SSN or ITIN card"
      },
      "ssn-title": "Verify your SSN or ITIN",
      "submit": "Send for review",
      "thanks": "Thanks for uploading your {type}",
      "unable": "Don't have any of these documents?",
      "under-3-years": "Is under 3 months old",
      "unexpired": "Unexpired",
      "upload": "Verify your details",
      "upload-bill-instruction": "Please upload a copy of a utility bill",
      "upload-docs": "Upload <strong>one</strong> of these documents:",
      "upload-failed": "Sorry, we were unable to upload your documents. Please try again.",
      "upload-failed-title": "Upload Failed",
      "upload-instruction": "Please upload a picture of your {type, select, lease {lease agreement} bill {utility bill} license {driver's license} id {ID card} medicare {Medicare Card} passport {passport} ssn {SSN or ITIN card} other {} }.",
      "upload-not-enough": "Sorry, please select two files, the first and last page (with signatures) of the Lease",
      "upload-title": "Upload {fileCount, plural, =0 { {type, select, lease { your lease agreement } bill { a utility bill } license { your driver's license } id { your ID card } medicare { your Medicare Card } passport { your passport } ssn { your SSN or ITIN card } other {} } } other { another page } }",
      "upload-too-large": "Sorry, there was an error uploading your file. Please check the file size (max 20MB) and try again.",
      "upload-too-many": "Sorry, please select only two files, the first and last page with signatures and the Lease Terms",
      "upload-wrong-type": "Sorry, we can’t use that file – please use a PDF, JPEG or PNG under 20MB.",
      "uploaded": "Uploaded",
      "use-photo": "Use Photo",
      "verified": "Approved",
      "verify-address": "Verify your address",
      "verify-identiy": "Verify your name & date of birth",
      "we-accept": {
        "bills-1": "Electric, heat, gas or water bills",
        "bills-2": "Home, internet or phone bills",
        "bills-3": "Cable TV bills",
        "header": {
          "additional-lease": "Please upload a copy of the other page of your lease agreement.",
          "bill": "We accept",
          "lease": "Please upload a copy of your current lease agreement or take pictures of the pages showing:"
        },
        "lease-1": "Your legal name",
        "lease-2": "Your current residential address",
        "lease-3": "Your signature",
        "lease-4": "The landlord’s signature",
        "lease-5": "The dates for the lease",
        "lease-6": "These are usually found on the first and last pages."
      },
      "we-dont-accept": {
        "bills-1": "Bills with past due balances",
        "bills-2": "Bills older than 3 months",
        "bills-3": "Cell phone bills",
        "bills-4": "Credit card and bank statements",
        "bills-5": "Medical bills",
        "header": "We don't accept"
      },
      "wrong": "Wrong details?",
      "yes": "Yes"
    },
    "empty-string": "",
    "empty-workspace": {
      "not-sure": "Not sure what to do? Watch the platform walkthrough",
      "start-trading": "Select a market on the left to start trading.",
      "title": "Get started",
      "video": "video.",
      "walkthrough": "New Nadex Platform Walkthrough"
    },
    "error": {
      "check-connection": "Oh no… your internet connection is unavailable. Please check the connection and try again",
      "exchange-closed": "The exchange is closed.",
      "exchange-count": "The exchange will open in ",
      "go-back": "Go back",
      "header": "This page could not be loaded",
      "no-connection": "No internet connection",
      "not-found-header": "This page was not found",
      "offline": "Your internet is unavailable. Please check your connection.",
      "retry": "Retry",
      "see-opening-hours": "See opening hours",
      "sub-header": "Try refreshing your device to continue trading.",
      "unverified": "Verify your account now to start trading."
    },
    "errors": {
      "close-deal-not-possible-1": "There is no current market price available to close your position.",
      "close-deal-not-possible-2": "From the positions tab, you can click directly on your open position and submit a working order.",
      "direction-invalid": "Please choose Sell or Buy",
      "price-invalid": "Price must be between {minPrice} and {maxPrice}",
      "price-invalid-increment": "The price is not a valid increment. Please use increments of {increment}",
      "size-above-max": "Size must not exceed {max}",
      "size-below-min": "Size must be at least {min}"
    },
    "expiries": {
      "select-expiry": "Select expiry",
      "select-market": "Select market"
    },
    "feedback": {
      "introduction": "Let us know what you think of our service and if there’s anything we can do better.",
      "submit": "submit feedback",
      "thank-you": "Thank you for your feedback"
    },
    "generic-error": {
      "banner": {
        "link": "See more",
        "text": "We’ve run into a problem with your account"
      },
      "modal": {
        "close-button": "Do it later",
        "footer": "Find out how we manage your data in our <a href='https://assets.ctfassets.net/8c2uto3zas3h/3m635R9KxxvKWIKDhTJa6p/f180ebb5541238aab28b65980b01d372/Nadex_PrivacyPolicy.pdf' target='_blank' rel='noopener noreferrer'>Privacy Policy</a>",
        "text-1": "We need to verify some information on your account.",
        "text-2": "Please contact Customer Services at <a href='mailto:customerservice@nadex.com'>customerservice@nadex.com</a>.",
        "title": "Sorry, we’ve run into a problem"
      }
    },
    "home": {
      "account-overview": "Account Overview",
      "actions": "You can still go to My Account to deposit or withdraw funds.",
      "available-desktop": "Available",
      "balance": "Available balance",
      "closed": "Nadex is currently closed",
      "home": "Home",
      "hours": "See the opening hours</a>",
      "pnl": "Total P&L",
      "resume-date": "You can resume trading at {time} EST on {date}.",
      "resume-shortly": "You can resume trading shortly.",
      "weekend-closed": "The exchange will re-open at 6pm EST on Sunday."
    },
    "inactive-user": {
      "banner": {
        "link": "click here",
        "title": "Your account is on hold. To start activation process"
      },
      "modal": {
        "download-form-button": "Download Form",
        "footer-link": "Still have questions?",
        "footer-text": "We’ll contact you within 1 business day!",
        "steps": {
          "step1-counter": "1",
          "step1-text": "Download and fill out the re-certification form",
          "step2-counter": "2",
          "step2-text": "Take a picture of the front and back of your ID",
          "step3-counter": "3",
          "step3-email": "customerservice@nadex.com",
          "step3-text": "Email the completed form and ID images to"
        },
        "text": "Your account has been inactive for over a year! Please follow the steps below to reactivate your account:",
        "title": "Hi {name}, welcome back!"
      }
    },
    "info-message": {
      "bookPrice": "No top of book price shown. Please try when prices are shown",
      "cancelled": "Order Cancelled",
      "ceil": "Ceiling:",
      "close": "✕",
      "close-deal-message": "{size} contract(s)",
      "dash": "-",
      "error": "There was an error placing the trade, please try again later",
      "floor": "Floor:",
      "received": "Order Received",
      "ref": "Deal Reference:",
      "rejected": "Order Rejected",
      "unverified": "Deal not found. We can not verify that your action was successful."
    },
    "login": {
      "Bad request": "An unexpected server error occurred",
      "Failed to fetch": "An unexpected server error occurred",
      "Internal Server Error": "The platform is temporarily unavailable. Please try again later.",
      "Network request failed": "An unexpected server error occurred",
      "Popup closed": "Your pop-up session was closed. Please try logging in again.",
      "Timeout": "Your pop-up session timed out. Please try logging in again.",
      "auth0-popup": "Please complete your login within the auth0 popup",
      "button": "Login",
      "demo": "Demo",
      "demo-hint": "demo-",
      "enter-password": "Please enter a password",
      "enter-username": "Please enter a username",
      "forgot": "Forgotten your login details?",
      "generic-error": "We received an unexpected error. Please try again or contact Customer Service.",
      "inactivity-logout": "You have been logged out since you have been inactive for one hour. Please sign in again.",
      "incorrect-login": "Incorrect login.",
      "live": "Live",
      "login": "Login",
      "mirage": "Mirage",
      "net": "Test",
      "no-internet": "No internet connection",
      "no-internet-1": "Check your connection and try again.",
      "open-account": "Don't have an account?",
      "open-account-link": "Open an account",
      "password": "Password",
      "reset": "Want to Reset your password?",
      "service.security.authentication.failure-account-access-denied": "The account has been denied login privileges",
      "service.security.authentication.failure-account-not-activated": "The account has not been activated yet",
      "service.security.authentication.failure-account-suspended": "The account has been suspended",
      "service.security.authentication.failure-all-accounts-pending": "All of the accounts are in a pending state",
      "service.security.authentication.failure-all-accounts-suspended": "All of the clients accounts have been suspended",
      "service.security.authentication.failure-client-suspended": "The client has been suspended from using the platform",
      "service.security.authentication.failure-generic": "An unexpected server error occurred",
      "service.security.authentication.failure-invalid-details": "These details don't match our records. Please try again",
      "service.security.authentication.failure-max-attempts": "Maximum login attempts",
      "service.security.authentication.invalid-app-for-site": "The application is not supported for the site",
      "service.security.authentication.invalid-application": "The front end provided an invalid application type in the user agent",
      "service.security.authentication.invalid-website": "the site is in the block list or has been flagged as Omnibus",
      "title": "Account Login",
      "username": "Username",
      "vanity-features": "Continually evolving features",
      "vanity-loading": "Faster loading times",
      "vanity-sub": "We upgraded the Nadex trading platform. Now you have:",
      "vanity-title": "A new way to master volatility",
      "vanity-tools": "Powerful new charting tools",
      "web": "UAT"
    },
    "logout": "Logout",
    "market": {
      "indicative-price": "Indicative price",
      "last-update": "Last updated",
      "weekly": "Weekly"
    },
    "mini-chart": {
      "one-day": "1d"
    },
    "monitor": {
      "24-hour-history": "Order activity in the last 24hr"
    },
    "my-account": {
      "account-value": "Account value",
      "available": "Available to trade",
      "balances": "Account balances",
      "get-in-touch": "Get in touch",
      "overview": "Account Overview",
      "payment": "Payment",
      "position-value": "Position value",
      "profit-loss": "Total profit/loss",
      "replenish-balance": "Replenish balance",
      "reserved": "reserved",
      "switch-account": "Switch Account"
    },
    "no": "No",
    "ok": "Ok",
    "orders": {
      "at": "at",
      "binary": "Binary Options",
      "body": "You'll find your future orders here",
      "bracket": "Knock-outs",
      "contract": "Contract",
      "current": "Current",
      "deal-size": "Size",
      "expired": "Expired",
      "expiry": "Expiry",
      "filled": "filled",
      "header": "No orders",
      "indicative": "Indicative",
      "price": "Price",
      "spread": "Call Spreads",
      "time-left": "Time left",
      "type": "Type"
    },
    "payments": {
      "aba-number": "ABA:",
      "ach-deposit": "Add Funds With Bank",
      "add-funds": "Add Funds",
      "amount-error": "Please enter at least ${min}",
      "auth0-reauthenticate": "Please re-authenticate in the popup window to withdraw funds",
      "auth0-reauthentication-error-title": "Error re-authenticating",
      "auth0-reauthentication-required": "Re-authentication Required",
      "auth0-reauthentication-retry": "Retry",
      "available-days": "Funds available after 3-4 business days",
      "available-days-shorter": "Funds available after 2-3 business days",
      "available-immediately": "Funds available immediately",
      "available-receipt": "Funds available upon receipt",
      "back": "Back",
      "back-to-withdraw": "Withdraw funds",
      "bank-account": "Bank Account",
      "bank-transfer": "Bank transfer/ACH",
      "card-withdrawals-disabled": {
        "message-long": "Please note: Starting on October 10th, debit card withdrawals will be temporarily unavailable as we perform a payments system upgrade.<br/><br/> You can submit withdrawal requests via ACH or wire transfer during this time. You can continue to make debit card deposits during the upgrade.<br/><br/>If you have yet to do so, we encourage you to set up your account for ACH or wire transfer withdrawals as soon as possible. If you need assistance, please e-mail <a class='withdrawals-disabled_email' href='mailto:payments@nadex.com'>payments@nadex.com</a> and we will be happy to help you!",
        "message-short": "Please note: Starting on October 10th, debit card withdrawals will be temporarily unavailable as we perform a payments system upgrade.",
        "see-more": "See more"
      },
      "confirm": {
        "ach-deposit": "We've received your request to add <span>${amount}</span> to your Nadex account.",
        "ach-details": "ACH payments can take 3 to 4 business days to clear.",
        "card-available": "Your funds are now available for trading in your Nadex account.",
        "card-deposit": "Your <span>${amount}</span> deposit was successful.",
        "card-partial-withdraw": "Sorry, your withdrawal request was only partially completed.",
        "card-partial-withdraw-try-again": "Please resubmit another withdrawal request to complete your transaction.",
        "card-withdraw": "Your withdrawal of ${amount} to your debit card ending {cardEnding} was successful.",
        "card-withdraw-details": "Your funds will be available in 2-3 business days.",
        "close": "✕",
        "deposit-success": "Thanks for your deposit",
        "link": "GO TO ACCOUNT",
        "payment-email": "We’ll let you know once the payment goes through via email.",
        "wire-details": "We will inform you via email to let you know when the transaction will go through.",
        "withdraw": "Your request to withdraw <span>${amount}</span> to your <span>{bank}</span> bank account ending in <span>{number}</span> was submitted successfully.",
        "withdraw-success": "Withdrawal Successful"
      },
      "debit-card": "Debit Card",
      "deposit-amount": "Deposit amount",
      "deposit-amount-desktop": "Minimum deposit ${min}",
      "deposit-amount-desktop-initial": "Minimum initial deposit ${min}",
      "deposit-confirms": {
        "error": "Error",
        "failure": "Deposit failed",
        "on-hold": "Account on hold",
        "pending-verification": "Deposit failed pending verification",
        "success": "Deposit successful"
      },
      "deposit-error": "Deposit Error",
      "deposit-pending-limit": "The sum of your pending deposits exceeds the current limit of $10,000.",
      "deposit-title-desktop": "Amount to deposit",
      "desc": "Bank Accounts and Cards",
      "edit": "Edit",
      "ending-with": "Ending ",
      "error-messages": {
        "address-check-failed": "Sorry! Unfortunately, the address on file with Nadex does not match the address associated with your bank account. Please ensure that you have your current address on file with both Nadex and your bank.",
        "auth0-reauthentication-error-message": "To access this page you must re-authenticate to confirm your identity. Please click below to re-authenticate.",
        "authentication-required": "Please sign in with the Auth0 popup.",
        "bank-declined": "Sorry!  It appears your bank has declined this transaction. Please contact your bank to authorize withdrawals for Nadex and then resubmit the transaction! Thank you!",
        "card-country-restricted": "Sorry! We are unable to accept debit cards or card transactions from the country to which this card is registered.",
        "card-expired": "Sorry! We are unable to process your withdrawal to this card as it has expired.  Please try a different card.",
        "card-not-verified": "Hello! To withdraw funds to this card, please verify the card by submitting the required documentation in the 'Wallet' section of the platform.",
        "card-terminated": "Sorry! We are unable to process your withdrawal to this card as it has been terminated.  Please try a different card.",
        "card-used-too-recently": "Sorry! This card was used too recently for processing to be permitted.  Please try again in a few minutes.",
        "connection-error": "Sorry! There may have been an error with your deposit or withdrawal. Please contact Customer Service before resubmitting any further deposit or withdrawal requests.",
        "elevated-access-expired": "Your elevated access has expired and you must re-authenticate to continue.",
        "error-500": "We received an unexpected error. Please try again or contact Customer Service.",
        "generic-error": "We received an unexpected error. Please try again or contact Customer Service.",
        "member-not-active": "Sorry! Your account appears to be on hold.  Please contact Customer Service before submitting any further deposit or withdrawal requests.",
        "member-on-hold": "Sorry! Your account appears to be on hold.  Please contact Customer Service before submitting any further deposit or withdrawal requests.",
        "payment-provider-error": "Sorry! There was a processing error with your deposit or withdrawal. Please contact Customer Service before resubmitting any further deposit or withdrawal requests.",
        "payment-provider-problem": "Sorry! There was a processing error with your deposit or withdrawal. Please contact Customer Service before resubmitting any further deposit or withdrawal requests.",
        "payments-error-500": "We received an unexpected error. Please contact Customer Service before submitting any further deposit or withdrawal requests.",
        "payments-generic-error": "We received an unexpected error. Please contact Customer Service before submitting any further deposit or withdrawal requests.",
        "pending-deposit-limit": "The sum of your pending deposits exceeds the current limit of $10,000.",
        "rejected-by-exchange": "Sorry! We are unable to process your request. Please try again or contact Customer Service at the contact information below.",
        "service-unavailable": "Hello! We are currently performing technical maintenance. Please check back shortly.",
        "unexpected-error": "Sorry! There may have been an error with your deposit or withdrawal. Please contact Customer Service before resubmitting any further deposit or withdrawal requests."
      },
      "secure": "This is a secure 128-bit SSL encrypted payment",
      "select-bank": "Select Bank",
      "submit": "add funds",
      "unexpected-error": "Unexpected Error",
      "wire-transfer": "Wire transfer",
      "wire-transfer-details": "Wire Transfer Details",
      "withdraw-funds": "Withdraw Funds",
      "withdrawal-limits": {
        "how-much-subheader": "How much can I withdraw?",
        "how-much-text": "$10,000 per transaction and up to $50,000 per day. For larger amounts please submit a bank transfer withdrawal. ",
        "how-subheader": "How is card withdrawal limit calculated?",
        "how-text": "Each withdrawal limit is calculated by subtracting card withdrawals from card deposits, not to exceed account balance.",
        "title": "Card Withdrawal Limits",
        "what-subheader": "What are card withdrawal limits?",
        "what-text": "This is the amount available to withdraw to each card.",
        "why-subheader": "Why are card withdrawal limits used?",
        "why-text": "Card withdrawal limits are restricted by the payments processor and merchant bank for debit card transactions."
      },
      "withdrawal-unsuccessful": "Withdrawal Unsuccessful"
    },
    "positions": {
      "at": "at",
      "average-price": "Average price",
      "avg": "avg.",
      "binary": "Binary Options",
      "body": "You'll find your positions here",
      "bracket": "Knock-outs",
      "close": "Close",
      "contract": "Contract",
      "current": "Current",
      "day": "day",
      "days": "days",
      "deal-size": "Position",
      "expired": "Expired",
      "expires-in": "Expires in",
      "expiry": "Expiry",
      "header": "No positions",
      "indicative": "Indicative",
      "pnl": "Total P&L",
      "position": "Position",
      "position-value": "Position value",
      "probability-itm": "Probability ITM",
      "profit-loss": "Profit & Loss",
      "spread": "Call Spreads",
      "time-left": "Time left",
      "total": "Total",
      "type": "Type"
    },
    "profile": {
      "account": "Account",
      "address": "Residential Address",
      "appearance": "Appearance",
      "auth0-reauthenticate": "Please re-authenticate in the popup window to update contact details",
      "balance": "Balance",
      "binaries-tolerance": "Binaries: ${value}",
      "cancel": "Cancel",
      "change-password": {
        "confirmed-new-password": "Confirm new password",
        "error-body": "Something went wrong and your password has not been updated. Please try again later or contact <a class='link' rel='noopener noreferrer' target='_blank' href='https://www.nadex.com/contact/'>Customer Services</a>",
        "error-empty-field": "Field cannot be empty",
        "error-password-does-not-meet-criteria": "Make sure your password is between 8-20 characters and contains at least one capital letter, one lower case letter and one number.",
        "error-passwords-do-not-match": "Passwords don't match",
        "error-subheader": "Password Not Changed",
        "has-number": "1 number",
        "lower-case-letter": "1 lower case letter",
        "menu-title": "Change Password",
        "new-password": "New password",
        "old-password": "Old password",
        "password-length": "8-20 characters",
        "submit": "Confirm",
        "success-body": "Your password has been successfully changed.",
        "success-subheader": "Password Changed",
        "title": "Change Password",
        "trader-explanation": "To change your password for a trader account, please contact Nadex Customer Services at <a href='mailto:customerservice@nadex.com'>customerservice@nadex.com</a>.",
        "upper-case-letter": "1 upper case letter"
      },
      "coming-soon": "Coming soon",
      "confirm-switch-demo": "Switch to your Demo account?",
      "confirm-switch-live": "Switch to your Live account?",
      "contact": {
        "email": "Email Address",
        "number": "Phone Number"
      },
      "contact-details": "Contact Details",
      "contact-us": "Contact Us",
      "contract-size": "Default Contract Size",
      "contract-sizes": {
        "error": "Value must be between 1 and 1000",
        "field": "Contract size",
        "info": "You can still modify the number of contracts before placing a trade",
        "intro": "Set a default size to help you trade faster."
      },
      "current": "Current: {value}",
      "dark": "Dark",
      "desktop-reporting-message": "We are working on a new Reporting section. In the meantime, to see your statements and order history",
      "discard": "discard",
      "display-settings": "Display Settings",
      "download": "Download",
      "edit": "Edit",
      "errors": {
        "email": "Please enter an email address",
        "number": "Please enter a phone number"
      },
      "explain-switch-demo": "You will need to log in to your Demo account to continue.",
      "explain-switch-live": "You will need to log in to your Live account to continue.",
      "feedback": "Send Feedback",
      "feedback-title": "Feedback",
      "get-in-touch": "Get in touch",
      "history": {
        "back": "Back",
        "csv-headers-orders": "Result,Date,Time,Market,Size",
        "csv-headers-transactions": "Type,Date,Time,Description,Amount",
        "custom": "Custom",
        "custom-dates": "Custom Dates",
        "date-format": "mm/dd/yyyy",
        "date-range-too-large": "The maximum date range is 90 days",
        "dismiss-modal": "close",
        "download": "Download",
        "download-error-no-history": "We couldn't find any history for your search. Please select different parameters or change the time period.",
        "download-failed": "Download Failed",
        "end-date": "End Date:",
        "end-in-future": "The end date must not be in the future",
        "from-last": "From Last:",
        "from-to": "From/To:",
        "header-amount": "Amount",
        "header-date": "Date",
        "header-description": "Description",
        "header-time": "Time",
        "header-type": "Type",
        "history": "History",
        "invalid-dates": "Please select a start and end date",
        "last-period": "Last {period}",
        "ninety-days": "90 Days",
        "no-results": "We couldn’t find any history for your search. <br />Please go back to select different parameters or change the time period.",
        "order": "Order History",
        "order-description": "Shows all entered, modified, and cancelled orders, as well as order requests that have been rejected.",
        "order-rejected": "Rejected: Insufficient funds",
        "results-heading-order-history": "Order History",
        "results-heading-transactions-all": "Transaction History",
        "results-heading-transactions-all-deal": "Trading Activity",
        "results-heading-transactions-all-financial": "Fund History",
        "seven-days": "7 Days",
        "show": "Show:",
        "six-hours": "6 Hours",
        "sixty-days": "60 Days",
        "start-after-end": "The start date must be before the end date",
        "start-date": "Start Date:",
        "thirty-days": "30 Days",
        "three-days": "3 Days",
        "transaction": "Transaction History",
        "transaction-description": "View details of all executed orders and payments or adjustments made to and from your account.",
        "twelve-hours": "12 Hours",
        "twenty-four-hours": "24 Hours",
        "type": "Type:",
        "type-all": "All",
        "type-all-deal": "Trading Activity",
        "type-all-deal-definition": "Displays trade-related information such as prices paid for contracts, trading fees, and payouts.",
        "type-all-definition": "Displays all transactions.",
        "type-all-financial": "Fund History",
        "type-all-financial-definition": "Displays deposits and withdrawals made to and from your account.",
        "view-history": "View History",
        "whats-this": "What's this?"
      },
      "inactivity": {
        "heading": "Session Inactivity",
        "intro": "For your security, your Nadex trading session will expire after <strong>one hour</strong> of inactivity.<br/><br/>If you leave this page inactive for more than 60 minutes you will be logged out automatically."
      },
      "light": "Light",
      "light-dark-theme": "Light/Dark Theme",
      "limit": "Limit",
      "market": "Market",
      "mobile-reporting-message": "We are working on a new Reporting section. To access your statements and history now, please go to the old desktop platform.",
      "non-binary-tolerance": "Knock-outs and Spreads: ${value}",
      "ok": "OK",
      "order-type": "Default Order Type",
      "order-types": {
        "info": "You can still change the order type on a trade by trade basis.",
        "intro": "This setting will define the default order type available in the ticket across all markets."
      },
      "payment": "Payment",
      "payments": "Payments",
      "personal-details": "Personal Details",
      "profile": "Profile",
      "replenish-balance": {
        "cancel-button": "Cancel",
        "confirm-button": "Confirm",
        "description-1": "Your demo balance will reset to $10,000. Press <strong>Confirm</strong> to replenish or <strong>Cancel</strong> to resume trading.",
        "description-2": "Your funds can only be reset once every 24 hours.",
        "error": {
          "account-expired": "Your demo account is expired. To continue using your demo account, you must open and fund a live trading account.",
          "already-requested": "You can only replenish your balance once every 24 hours. You can replenish your account in",
          "balance-at-maximum": "We can’t reset your demo balance because your balance is currently $10,000 or over. You can only replenish balances under $10,000.",
          "generic-error": "Sorry! It looks like something went wrong. Please try again."
        },
        "menu-label": "Replenish balance",
        "success-description": "Your demo balance has been reset to $10,000.",
        "success-title": "Replenishment Successful",
        "title": "Replenish balance"
      },
      "reporting": "Reporting",
      "reporting-coming-soon": "Reporting Coming Soon",
      "save": "Save",
      "save-failed": "Sorry, we were unable to save your preferences. Please try again.",
      "save-failed-contact": "Sorry, we were unable to save your contact details. Please try again.",
      "save-failed-title": "Save Failed",
      "settings": "Settings",
      "statement": "Statement",
      "suggest-email": "Did you mean {address}?",
      "switch-demo": "Switch to Demo",
      "switch-live": "Switch to Live",
      "system": "System",
      "tax-statement": "Tax Forms",
      "theme": "Theme",
      "tolerance": "Tolerance",
      "tolerances": {
        "binaries": "Binaries",
        "error": "Value must be between $1 and $25",
        "instructions": "Values can be set between $1 and $25",
        "intro": "Market Orders can have a built-in tolerance range, outside of which you can't get filled. This caps the amount of risk you are willing to accept when submitting an order.",
        "min": "Min: {min}",
        "non-binary": "Knock-outs and Spreads",
        "server-error": "Value could not be saved. Please try again.",
        "sub-title": "Tolerance amount per contract"
      },
      "trade-settings": "Trade Settings",
      "unsaved": "Unsaved changes",
      "unsaved-details-contact": "You have unsaved changes to your contact details. <br /> Do you want to discard the changes?"
    },
    "profit-target": {
      "additional-info": "After placing the limit order, you can delete or amend the position under \"Orders\" tab.",
      "bracket-or-spread-question": "Current position: <span class='ticket_question-name'>{market} {price} {direction}{size} @ {averagePrice}</span>",
      "closing-price-label": "Closing price",
      "current-profit-target-label": "Profit Target is set to {profitTarget}",
      "fees-not-included": "Profit target excludes <a href='https://www.nadex.com/blog/setting-a-profit-target-in-the-nadex-platform/' target='_blank' rel='noopener noreferrer'>fees</a>",
      "full-question": "Current position: <span class='ticket_question-name'>{market} {condition} {price} {direction}{size} @ {averagePrice}</span>",
      "higher-or-equal": ">=",
      "higher-than": ">",
      "no-profit-error": "Set profit target is unavailable on this position",
      "positions-list-button": "Set profit target",
      "profit-target-label": "Profit target",
      "set-profit-target": "Please set your Profit Target below:",
      "submit-button": "Place Limit Order"
    },
    "qr-code-modal": {
      "close": "✕",
      "footer-1": "Need more details?",
      "footer-2": "Learn more",
      "header": "Try the Nadex Mobile App and trade wherever you are!"
    },
    "save-to-homescreen": {
      "add-to-homescreen": "Add to Home Screen",
      "android-instruction-1": "Tap <div class='install-banner_icon-container install-banner_icon-container--berries'></div> icon and tap <strong>Add to Home Screen</strong>",
      "app-title": "Nadex",
      "app-url": "https://platform.nadex.com",
      "ios-instruction-1": "Tap the <strong>Share</strong> icon",
      "ios-instruction-2": "Tap <strong>Add to Home Screen</strong>",
      "sub-heading": "Save a shortcut to this app on your phone for fast, full-screen trading"
    },
    "statement": {
      "30-days": "This page shows the statement for the last 30 days",
      "not-loaded": "Statement could not be loaded"
    },
    "supported-browsers": {
      "chrome": "Chrome",
      "edge": "Edge",
      "firefox": "Firefox",
      "safari": "Safari",
      "text": "To enjoy the best trading experience, please download the latest version of the supported browsers:",
      "title": "Your browser is not supported"
    },
    "tax-statement": {
      "disclaimer": "If you had any trading activity during the year, your 1099-B tax form will be listed below no later than February 15th. For more tax-related information  <a target='blank' href='https://www.nadex.com/learning/how-does-nadex-provide-income-tax-related-information/'>click here.</a>",
      "download": "Download",
      "errors": {
        "FORM_NOT_FOUND": "No tax forms found for {year}, this could be because you had no trading activity for this fiscal year. If you think this is a mistake, please contact customer services.",
        "GENERIC_ERROR": "We are unable to retrieve your tax forms at this time. Please try again later."
      },
      "header": "Nadex Tax Forms",
      "loading": "Loading statement...",
      "statement-year": "Statement Year"
    },
    "tearoff": {
      "hide-chart": "Hide Chart",
      "hide-ticket": "Hide Ticket"
    },
    "ticket": {
      "amend-order": "Amend order",
      "average-price": "Average Price",
      "bracket-or-spread-question": "This <span class='ticket_question-name'>{market}</span> <span class='ticket_question-price'>{price}</span> contract expires @{time}",
      "buy": "Buy",
      "call": "This market is currently on call",
      "close-deal": "Close position",
      "close-deal-bracket-or-spread-question": "Close position <span class='ticket_question-name'>{market} {price} @{time}</span> at the current market price?",
      "close-deal-full-question": "Close position <span class='ticket_question-name'>{market} {condition} {price}</span> at the current market price?",
      "close-deal-full-question-with-time": "Close position <span class='ticket_question-name'>{market} {condition} {price} @{time}</span> at the current market price?",
      "close-deal-higher-or-equal": ">=",
      "close-deal-higher-than": ">",
      "closed": "This underlying market is currently closed.",
      "closed-with-link": "This underlying market is currently closed. <br> <br> Check the opening hours on the <a class='link' rel='noopener noreferrer' target='_blank' href='https://www.nadex.com/contract-specs-stock-index/'>market trading hours page</a>",
      "contract-expired": "This contract is expired",
      "current-market-price": "Current market price",
      "current-profit-loss": "Current profit & loss",
      "delete-order": "Delete order",
      "expired": "This option has expired.",
      "expires": "Expires",
      "fullQuestion": "Will <span class='ticket_question-name'>{market}</span> finish {condition} <span class='ticket_question-price'>{price}</span>?",
      "fullQuestionWithTime": "Will <span class='ticket_question-name'>{market}</span> finish {condition} <span class='ticket_question-price'>{price}</span> @{time}?",
      "hide-depth": "Hide market depth",
      "higher-or-equal": "higher than or equal to",
      "higher-than": "higher than",
      "if-hitting": "if <strong>{value}</strong> touched",
      "limit": "Limit GTC",
      "login-to-trader": "To enable trading, please login to a Trader account.",
      "market": "Market",
      "market-order-info": "By clicking <strong>Close Position</strong> you will submit a market order. Depending on market conditions your position may not be closed.",
      "max-loss": "Max loss",
      "max-profit": "Max profit",
      "max-roi": "Max ROI",
      "max-roi-tooltip": "<strong>Maximum ROI<br>(Return On Investment)</strong><br>The maximum ROI is calculated using the best current bid or offer price at a particular moment and assumes you hold the contract until expiration to receive the full $100 payout per contract, minus the cost of your trade including fees.<br><br>Nadex provides no guarantee of these returns and assumes no liability for trading decisions based on this Maximum ROI tool. Any trading decisions you make are solely your responsibility and at your own risk. <a href='https://www.nadex.com/learning/max-roi/' target='_blank' rel='noopener noreferrer' class='tooltip-link'>See more</a>",
      "min": "Min: {min}",
      "min-price": "Min: {min}",
      "no": "No",
      "no-depth": "No other prices available on this market.",
      "open-chart": "Open Chart",
      "place-deal": "Place order",
      "price": "Price",
      "price-ladder": "Price Ladder",
      "probability-itm": "Probability ITM",
      "probability-itm-tooltip": "<strong>Implied Probability ITM<br>(in-the-money)</strong><br>Binary options are priced between $0 to $100. This tool calculates the midpoint between the current bid/ask to give you the probability of a contract settling in-the-money (ITM) at expiration.<br><br>For example, as a buyer, if the current bid is $38 and offer is $42, the buyer’s probability of being correct is 40%. The seller is just the opposite, which would be 60%.<br><br>Nadex provides no guarantee of profit and assumes no liability for trading decisions based on this tool. Any trading decisions you make are solely your responsibility and at your own risk. <a href='https://www.nadex.com/learning/implied-probability-itm/' target='_blank' rel='noopener noreferrer' class='tooltip-link'>See more</a>",
      "question": "finish higher than ",
      "sell": "Sell",
      "show-depth": "Show market depth",
      "size": "Size",
      "switch-to-default-chart": "Switch to Default Chart",
      "switch-to-master": "To enable trading, please switch to the Master account.",
      "switch-to-quick-chart": "Switch to Quick Chart",
      "tear-off-chart": "Tear-off chart",
      "tear-off-ticket": "Tear-off ticket",
      "type": "Order type",
      "yes": "Yes"
    },
    "trader-switched-modal": {
      "dismiss": "Got It",
      "dont-show-again": "Don't show this message again",
      "text-1": "You can start trading right away.",
      "text-2": "If you wish to switch back to your Trader account, please go to the Account section.",
      "title": "You have been automatically switched to the Master account"
    },
    "trades": {
      "closed": "History",
      "history": "history",
      "history24": "<span class='hours'>24hr</span> history",
      "orders": "Orders",
      "positions": "Positions",
      "trades": "Trades"
    },
    "unverified": {
      "explanation": "Your account is currently pending. Please visit the trading app using your desktop/laptop and upload the required documents to activate your account.",
      "title": "Activate your account"
    },
    "verification": {
      "back": "Back"
    },
    "wallet": {
      "account": "Account",
      "add": "Add",
      "add-bank": "Add bank account",
      "add-card": "Add debit card",
      "add-funds-with-card": "Add funds with card",
      "bank": {
        "ach-contact": "Alternatively you can contact our helpdesk at",
        "ach-details": "See details required",
        "ach-failed": "ACH Verification Failed",
        "ach-failed-explain": "We're sorry, based on the information provided we will need some extra details from you before you can use this bank account.",
        "ach-verification": "ACH Verification",
        "add-bank-details": "Add bank details manually*",
        "add-bank-disclaimer": "*This could require additional verification steps.",
        "add-debit-card": "Add a personal debit card",
        "add-personal-bank-account": "Add a personal bank account*",
        "address": "Registered residential address",
        "bank": "Bank",
        "bank-account-number": "Bank Account Number",
        "checking-account-not-found": "We couldn't find a checking account",
        "close": "Close",
        "confirm-account": "I confirm this is my personal account and that the name and ownership of this bank account match the name on my Nadex account.",
        "confirm-us-account": "I confirm this is my personal checking account and that the name and ownership of this bank account match the name on my Nadex account.",
        "contact-us": "Contact us if these details are wrong.",
        "continue": "continue",
        "country": "Country",
        "default-bank": "Make this your default bank",
        "delete": "DELETE BANK",
        "delete-warning": "Once deleted this bank will no longer be available in your account. <br /><br /> Are you sure you want to delete the selected bank?",
        "disclaimer": "Please be advised that by clicking \"Add Bank Account\", your information will be submitted to our third-party service provider, in accordance with the <a class='link'  rel='noopener noreferrer' href=\"https://www.nadex.com/legal\">Nadex Privacy Policy</a>, for verification and authentication of this bank account.",
        "email": "Email: ",
        "error": "We’re sorry but there seems to be an issue with your bank account. This can be sorted by getting in touch with your bank.",
        "fund-with-debit-card": "Add funds via a personal debit card",
        "iban": "IBAN",
        "incorrect": "Are the details above incorrect?",
        "intro-non-us": "Please make sure the information you enter below is accurate to avoid a returned deposit fee being assessed to your account. ",
        "intro-us": "Make sure you add a personal checking account which matches your Nadex account name.",
        "is-bank-us": "Is your bank based in the US?",
        "manual-title": "Add Bank Details Manually",
        "multiple-accounts": "We have found the following checking accounts at <strong>{bankName}</strong>. Make sure you select a personal checking account which matches you Nadex account name.",
        "no": "No",
        "no-accounts": "We’re sorry but it appears that there are no valid checking accounts associated with your <strong>{bankName}</strong> account.",
        "no-us-bank": "Sorry, ACH is only available for banks based in the U.S. Please, go to your wallet to add or edit your bank details.",
        "only-checking-accounts": "We can only allow personal checking accounts to add and withdraw funds to your Nadex account.",
        "owner": "Account owner",
        "plaid-additional-information-disclaimer": "*This could require additional verification steps.",
        "plaid-business-account-disclaimer": "*Funding from a business account is not permitted.",
        "plaid-connection-failed-text": "We’re sorry but we’ve not been able to connect to your <strong>{bankName}</strong> account at this time.",
        "plaid-connection-failed-title": "We couldn't connect to your bank at this time",
        "plaid-early-exit-text": "You’ve just exited the quickest and most secure way to link your bank account to Nadex.",
        "plaid-early-exit-title": "Exit Plaid account verification",
        "plaid-explainer": "We use a third-party service called Plaid to verify checking accounts based in the US.",
        "plaid-not-loaded-text": "We’re sorry but we can’t automatically verify your bank account via Plaid at the moment.",
        "plaid-not-loaded-title": "We couldn't start Plaid",
        "plaid-other-options": "or alternatively select any of the following options:",
        "plaid-try-again": "try again to add a bank via Plaid",
        "plaid-you-can": "You can",
        "please-select": "Please select",
        "primary-account": "Primary Bank",
        "proceed": "Proceed",
        "relaunch-plaid": "Go back to Plaid",
        "routing-number": "ABA / Routing Number",
        "save": "Add Bank Account",
        "select-account": "Select An Account",
        "submit": "Submit documents",
        "swift": "SWIFT / BIC Code",
        "telephone": "Talk to us free of charge on ",
        "title": "Add Bank Details",
        "update-title": "Update your details",
        "verification": "Confirm Details",
        "verification-intro": "Please verify the information below as these will be checked against by the ones held by your bank:",
        "went-wrong": "Something went wrong",
        "what-next": "What can you do next?",
        "which-account": "Which account would you like to use?",
        "yes": "Yes"
      },
      "bank-failure": "We’re sorry, there’s been an error retrieving your bank accounts. Please try again later.",
      "banks": "Bank accounts",
      "call": "Call",
      "cancel": "Cancel",
      "card-failure": "We’re sorry, there’s been an error retrieving your cards. Please try again later.",
      "card-hint": "As shown on your card",
      "card-holder": "Card Holder Name",
      "card-holder-with-hint": "Card holder name (as shown on your card)",
      "card-number": "Card Number",
      "cards": "Debit cards",
      "default-card": "Make this your default card",
      "delete": "Delete",
      "delete-card": "Delete Card",
      "delete-warning": "Once deleted this card will no longer be available in your account.<br><br>Any pending deposits or withdrawals might be cancelled.",
      "ending": "Ending",
      "ending-with": "Ending with",
      "exp": "Exp",
      "expired": "Expired",
      "expiry": "Expiry Date (MM/YY)",
      "invalid-expiry": "The expiry date must be in the future",
      "invalid-month": "Please enter a month between 01 and 12",
      "invalid-year": "Please enter a two digit year",
      "manage": "MANAGE MY WALLET",
      "max": "Maximum 2",
      "one-left": "1 left",
      "out-bank": "All banks used",
      "out-card": "All cards used",
      "primary": "Primary",
      "primary-card": "Primary Card",
      "proof-of-deposit": "You will need to provide proof of deposits made from this card before deleting it.",
      "save-changes": "Save changes",
      "verification-required": "Verification required",
      "verify": {
        "bank": "Verify Your Bank Account",
        "bank-details": "Please make sure your statement includes the following information:",
        "bank-details-1": "Your full name",
        "bank-details-2": "Bank account number",
        "bank-details-3": "Bank logo",
        "bank-details-4": "Nadex debit card transaction",
        "bank-statement": "Copy of bank statement",
        "card": "Verify Your Card",
        "card-details": "If the Nadex transaction is not yet shown on your statement, please provide an online banking screenshot in addition to your most recent monthly bank statement, showing the Nadex debit card transaction with at least the last four digits of your checking account number.",
        "email": "Email your documents to ",
        "id": "Copy of your photo ID",
        "id-details": "Any valid ID document with a clear photo.",
        "intro": "To verify the account, please provide us with the following documents:",
        "open-email": "Open email",
        "request-bank": "Your bank needs to be verified before we can allow you to request a withdrawal.",
        "request-card": "Your card is unverified. Please verify your card now",
        "subject": "ACH Bank Verification",
        "title": "Verify"
      },
      "verify-to-delete": "You will need to provide proof of deposits made from this card before deleting it.",
      "wallet": "Wallet",
      "wallet-failure": "We’re sorry, there’s been an error retrieving your cards and bank accounts. Please try again later."
    },
    "welcome": {
      "header": {
        "page-1": "Welcome to Nadex!",
        "page-2": "Trade Around the Clock",
        "page-3": "Access 29+ Markets",
        "page-4": "The Future of Mobile Trading"
      },
      "next": "NEXT",
      "paragraph": {
        "page-1": "Designed to give you direct-market access to our full range of markets and products.",
        "page-2": "Thousands of weekly trade opportunities, 23 hours a day, Sunday-Friday.",
        "page-3": "Forex, indices, commodities, economic events, and cryptocurrency markets at your fingertips.",
        "page-4": "Add Nadex to your home-screen for an optimized trading experience!"
      },
      "skip": "Skip",
      "start": "LET'S START"
    },
    "welcome-message": {
      "lastest-updates": "Read the latest updates about our products and platform",
      "mark-as-read": "Mark as read",
      "messages": "Messages",
      "read-now": "Read now",
      "updated-information": "Updated information"
    },
    "wire-transfer": {
      "account-number": "353-512-7",
      "account-number-label": "Account Number",
      "bank-address": "BMO Harris Bank, 111 W.Monroe Street, Chicago, IL 60603",
      "bank-address-label": "Bank Address",
      "header": "Nadex Bank Account",
      "initiation-non-us": "An international bank transfer must be initiated by your bank and sent to us in U.S. dollars. The transfer will need to be sent to the following:",
      "initiation-us": "A wire deposit must be initiated by your bank and sent to the following Nadex bank account:",
      "name": "North American Derivatives Exchange",
      "name-label": "Name",
      "non-us": "Non-US Wire",
      "restrictions": "Once notified by the bank we will immediately credit your deposit to your Nadex account.",
      "routing-number": "071000288",
      "routing-number-label": "Routing Number",
      "swift-code": "HATRUS44",
      "swift-code-label": "Swift Code/BIC",
      "us": "US Wire",
      "what-to-include": "Please include your name, Nadex username and the address on your Nadex account. Your bank may also require our <strong>company address</strong>"
    },
    "withdraw": {
      "aba": "ABA",
      "aba-error": "Please enter a 9 digit ABA number",
      "aba-number": "Wire ABA number",
      "ach": "Withdraw via ACH",
      "add-bank": "Add bank",
      "amount": "Withdrawal Amount",
      "amount-error": "The amount must be greater than $25 and less than your available balance",
      "available": "Account balance",
      "bank": "Select Bank",
      "bank-account": "Bank account",
      "card": "Withdraw via Debit Card",
      "fees": "Withdrawal Fees",
      "invalid-cards": "Invalid cards",
      "invalid-cards-dismiss-btn": "Close",
      "invalid-cards-error": "It looks like you need to verify some information before making a withdrawal.",
      "maximum": "Sorry. The maximum withdrawal amount is ${max}.",
      "minimum": "Sorry. The minimum withdrawal amount is $1.",
      "net": "Net Withdrawal",
      "no-cards": "Sorry, it looks like there are no cards which meet the withdrawal criteria.",
      "number": "Number ending",
      "ok": "OK",
      "send-request": "Send withdrawal request",
      "tooltip": {
        "calculate-info-dismiss-btn": "Close",
        "calculate-info-link": "Read More",
        "calculate-info-text": "Card withdrawal limits are the difference between the Total Deposits and Total Withdrawals made from the selected card.<br /><br />Amounts above the single card limit can be withdrawn back to another verified card or bank.<br /><br />Additional limits are in place for single transactions - up to $10K - or daily activity - up to $50K.<br /><br />Read more about card withdrawal limits and why we apply them below. ",
        "calculate-info-title": "Card Withdrawal Limit",
        "card-requires-verification-dismiss-btn": "Close",
        "card-requires-verification-link": "Verify Now",
        "card-requires-verification-text": "You can only withdraw funds to verified cards.",
        "card-requires-verification-title": "Unverified Card",
        "card-unfunded-dismiss-btn": "Ok",
        "card-unfunded-link": "",
        "card-unfunded-text": "You can only withdraw back to cards used to add funds to your account. </br></br> Please select another card or bank account to withdraw funds.",
        "card-unfunded-title": "Unfunded Card",
        "card-withdrawal-limit-reached-dismiss-btn": "Close",
        "card-withdrawal-limit-reached-link": "Read More",
        "card-withdrawal-limit-reached-text": "This card cannot be used because you have already reached the withdrawal limit allowed.</br></br> Please select another card or withdraw using a bank account.",
        "card-withdrawal-limit-reached-title": "Withdrawal Limit Reached",
        "close": "Close"
      },
      "usd": "USD",
      "use-bank": "To withdraw funds to a card, please verify all cards on your account.",
      "verify-now": "Verify now",
      "verify-text": "Sorry but you will need to verify the bank account and/or debit card(s) on file before you can withdraw.",
      "verify-title": "Verification required",
      "wire": "Withdraw via wire transfer"
    },
    "workspace": {
      "bitcoin-title-demo-only": "{marketName} (Demo Only)",
      "events-message-link": "how to trade events with Nadex.",
      "events-message-text": "See ",
      "feedback": "Let us know what you think of our platform and what we can improve.",
      "feedback-title": "Give feedback",
      "navigation": {
        "feedback": "Feedback",
        "live-chat": "Contact Us",
        "markets": "Markets",
        "mobile-app": "Mobile App",
        "settings": "Settings",
        "updates": "Updates"
      },
      "no-updates": "There are currently no updates to show.",
      "updates-and-news": "Updates & News"
    },
    "yes": "Yes"
  };
  _exports.default = _default;
});