define("nadex-web-app/templates/account/alerts/deposit/card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "gQbCYM5y",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"model\",\"successfulDeposit\"]]],null,{\"statements\":[[0,\"  \"],[5,\"payments-confirm\",[],[[\"@title\",\"@requestDetails\",\"@accountLink\"],[[28,\"t\",[\"payments.confirm.deposit-success\"],null],[28,\"t\",[\"payments.confirm.card-deposit\"],[[\"amount\",\"htmlSafe\"],[[24,[\"model\",\"successfulDeposit\",\"amount\"]],true]]],\"account.alerts.balances.profile\"]],{\"statements\":[[0,\"\\n    \"],[7,\"p\",true],[10,\"class\",\"payments-confirm_text\"],[8],[1,[28,\"t\",[\"payments.confirm.card-available\"],null],false],[9],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\",true],[11,\"class\",[29,[\"page card-holder card-payments\\n      \",[28,\"unless\",[[24,[\"model\",\"paymentsUiLoaded\"]],\"card-payment--spinner\"],null]]]],[8],[0,\"\\n    \"],[5,\"card-deposit-card\",[],[[\"@isMobile\",\"@support\",\"@onPaymentsLoad\",\"@origin\",\"@onClose\",\"@threeDSecure\",\"@onSuccess\",\"@onEditCard\"],[true,[24,[\"model\",\"support\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"model\",\"paymentsUiLoaded\"]]],null],true],null],[24,[\"model\",\"origin\"]],[28,\"route-action\",[\"transitionToProfile\"],null],[24,[\"model\",\"threeDSecure\"]],[28,\"route-action\",[\"onDepositSuccess\"],null],[28,\"route-action\",[\"onEditCard\"],null]]],{\"statements\":[[0,\"\\n      \"],[5,\"link-to\",[[12,\"class\",\"page_back\"]],[[\"@route\"],[[28,\"or\",[[24,[\"model\",\"origin\"]],\"account.alerts.deposit\"],null]]],{\"statements\":[[0,\"\\n        \"],[1,[28,\"t\",[\"payments.back\"],null],false],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n  \"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nadex-web-app/templates/account/alerts/deposit/card.hbs"
    }
  });
  _exports.default = _default;
});