define("nadex-web-app/utils/waitable-promise", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = waitablePromise;
  var pending = 0;
  function increment() {
    pending++;
  }
  function decrement() {
    pending--;
  }

  /* istanbul ignore else */
  if (false /* DEBUG */) {
    Ember.Test.registerWaiter(function () {
      return pending === 0;
    });
  }
  function waitablePromise(promise) {
    increment();
    return Ember.RSVP.resolve(promise).finally(decrement);
  }
});