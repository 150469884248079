define("nadex-web-app/utils/card-payment-errors", ["exports", "shared-components/utils/card-payment-errors"], function (_exports, _cardPaymentErrors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "GenericPaymentsError", {
    enumerable: true,
    get: function get() {
      return _cardPaymentErrors.GenericPaymentsError;
    }
  });
  Object.defineProperty(_exports, "errorStates", {
    enumerable: true,
    get: function get() {
      return _cardPaymentErrors.errorStates;
    }
  });
});