define("nadex-web-app/templates/components/price-ladder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "AwetLF9C",
    "block": "{\"symbols\":[\"instrument\",\"@onOpenTicket\",\"@expiry\",\"@market\",\"&default\",\"@strikes\"],\"statements\":[[14,5],[0,\"\\n\"],[4,\"each\",[[28,\"sort-by\",[[28,\"action\",[[23,0,[]],\"sortStrikes\"],null],[23,6,[]]],null]],null,{\"statements\":[[0,\"  \"],[7,\"dd\",true],[10,\"class\",\"row market-list_item\"],[8],[0,\"\\n    \"],[7,\"div\",false],[12,\"class\",\"cell\"],[12,\"role\",\"button\"],[3,\"action\",[[23,0,[]],[23,2,[]],[23,1,[]]]],[8],[0,\"\\n      \"],[1,[28,\"format-instrument-name\",[[23,1,[\"instrumentName\"]],[23,4,[\"name\"]],[23,3,[\"name\"]]],null],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[5,\"price-buttons\",[[12,\"class\",\"cell price-buttons\"]],[[\"@displayBid\",\"@displayOffer\",\"@instrument\",\"@onOpenTicket\"],[[23,1,[\"updates\",\"displayBid\",\"value\"]],[23,1,[\"updates\",\"displayOffer\",\"value\"]],[23,1,[]],[23,2,[]]]]],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nadex-web-app/templates/components/price-ladder.hbs"
    }
  });
  _exports.default = _default;
});