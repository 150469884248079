define("nadex-web-app/initializers/accounting", ["exports", "accounting/settings"], function (_exports, _settings) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'accounting.js',
    initialize: function initialize() {
      _settings.currency.format = {
        pos: '%s%v',
        neg: '-%s%v',
        zero: '%s%v'
      };
      _settings.currency.thousand = '';
      _settings.number.thousand = '';
    }
  };
  _exports.default = _default;
});