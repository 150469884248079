define("nadex-web-app/templates/components/display-settings-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "MOdLX9wT",
    "block": "{\"symbols\":[\"@onSelectSetting\",\"@theme\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"card_header\"],[8],[0,\"\\n  \"],[1,[28,\"t\",[\"profile.display-settings\"],null],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"card_body\"],[8],[0,\"\\n  \"],[7,\"a\",true],[10,\"role\",\"button\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],[23,1,[]],\"theme\"],null]],[10,\"class\",\"card_route-link card_route-link--flex\"],[8],[0,\"\\n    \"],[7,\"div\",true],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"profile.light-dark-theme\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[8],[0,\"\\n      \"],[7,\"span\",true],[10,\"class\",\"current-settings-text\"],[8],[0,\"\\n        \"],[1,[28,\"t\",[\"profile.current\"],[[\"value\"],[[23,2,[]]]]],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"a\",true],[10,\"role\",\"button\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],[23,1,[]],\"inactivity\"],null]],[10,\"class\",\"card_route-link card_route-link--flex\"],[8],[0,\"\\n    \"],[7,\"div\",true],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"profile.inactivity.heading\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nadex-web-app/templates/components/display-settings-card.hbs"
    }
  });
  _exports.default = _default;
});