define("nadex-web-app/templates/account/market-view/chart/ladder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "06a4ZZ2k",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"model\",\"chartController\",\"hideTicket\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"market-view_show-ticket\"],[8],[0,\"\\n    \"],[7,\"button\",false],[12,\"class\",\"market-view_show-ticket-button\"],[3,\"action\",[[23,0,[]],[28,\"route-action\",[\"toggleHideTicket\"],null]]],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"market-view_trade\"],[8],[0,\"\\n    \"],[5,\"market-list\",[[12,\"class\",\"market-list--compact\"]],[[\"@assetName\",\"@expandedItem\",\"@lightstreamer\",\"@market\",\"@marketTask\",\"@onSelectExpiry\",\"@onOpenTicket\",\"@underlyings\",\"@parent\"],[[24,[\"model\",\"selectedMarket\",\"name\"]],[24,[\"model\",\"expandedItem\"]],[24,[\"model\",\"lightstreamer\"]],[24,[\"model\",\"selectedMarket\"]],[24,[\"model\",\"marketTask\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"model\",\"expandedItem\"]]],null]],null],[28,\"route-action\",[\"openTicket\"],null],[24,[\"model\",\"underlyings\"]],[24,[\"model\",\"selectedAsset\"]]]]],[0,\"\\n    \"],[1,[22,\"outlet\"],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[7,\"div\",true],[10,\"class\",\"market-view_ticket-footer\"],[8],[0,\"\\n\"],[4,\"if\",[[28,\"not\",[[24,[\"model\",\"chartController\",\"hideChart\"]]],null]],null,{\"statements\":[[0,\"    \"],[5,\"pane-toggle\",[],[[\"@direction\",\"@label\",\"@labelPosition\",\"@open\",\"@onToggle\"],[\"right\",[28,\"unless\",[[24,[\"model\",\"chartController\",\"hideTicket\"]],[28,\"t\",[\"tearoff.hide-ticket\"],null]],null],\"before\",[28,\"not\",[[24,[\"model\",\"chartController\",\"hideTicket\"]]],null],[28,\"route-action\",[\"toggleHideTicket\",[28,\"not\",[[24,[\"model\",\"chartController\",\"hideTicket\"]]],null]],null]]]],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nadex-web-app/templates/account/market-view/chart/ladder.hbs"
    }
  });
  _exports.default = _default;
});