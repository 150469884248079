define("nadex-web-app/routes/account/alerts/balances/chart-epic-unselected", ["exports", "nadex-web-app/routes/account/alerts/balances/chart"], function (_exports, _chart) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _chart.default;
    }
  });
});