define("nadex-web-app/helpers/extract-binary-market-name", ["exports", "nadex-web-app/utils/instrument-name-to-details"], function (_exports, _instrumentNameToDetails) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.extractBinaryMarketName = extractBinaryMarketName;
  function extractBinaryMarketName(params, _ref) {
    var instrumentName = _ref.instrumentName;
    return (0, _instrumentNameToDetails.getBinaryMarketName)(instrumentName);
  }
  var _default = Ember.Helper.helper(extractBinaryMarketName);
  _exports.default = _default;
});