define("nadex-web-app/utils/is-inactive-member-status", ["exports", "shared-components/utils/is-inactive-member-status"], function (_exports, _isInactiveMemberStatus) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _isInactiveMemberStatus.default;
    }
  });
});