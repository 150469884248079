define("nadex-web-app/components/tolerance-setting-form", ["exports", "ember-diff-attrs"], function (_exports, _emberDiffAttrs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['card'],
    intl: Ember.inject.service(),
    didReceiveAttrs: (0, _emberDiffAttrs.default)('binaryTolerance', 'spreadTolerance', function (changedAttrs) {
      for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }
      this._super.apply(this, args);
      var binaryTolerance = changedAttrs && changedAttrs.binaryTolerance ? changedAttrs.binaryTolerance.lastObject : this.binaryTolerance;
      var spreadTolerance = changedAttrs && changedAttrs.spreadTolerance ? changedAttrs.spreadTolerance.lastObject : this.spreadTolerance;
      Ember.setProperties(this, {
        _binaryTolerance: binaryTolerance,
        _spreadTolerance: spreadTolerance
      });
    }),
    validate: function validate(value) {
      return value > 25 || value < 1 ? this.intl.t('profile.tolerances.error') : '';
    },
    actions: {
      submit: function submit(type) {
        var value = Ember.get(this, "_".concat(type, "Tolerance"));
        var error = this.validate(value);
        Ember.set(this, "".concat(type, "Error"), error);
        if (!error) {
          this.onSubmit("".concat(type, "MarketTolerance"), value);
        }
      }
    }
  });
  _exports.default = _default;
});