define("nadex-web-app/templates/account/alerts/balances/profile/switch-account", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "XmQEu5V5",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"page-stack\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"page card-holder trade-settings\"],[8],[0,\"\\n    \"],[5,\"link-to\",[[12,\"class\",\"page_back\"]],[[\"@route\"],[\"account.alerts.balances.profile\"]],{\"statements\":[[0,\"\\n      \"],[1,[28,\"t\",[\"profile.account\"],null],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n    \"],[5,\"switch-account-card\",[],[[\"@clientTokens\",\"@currentTokens\",\"@traderTokens\",\"@onSwitchAccount\"],[[24,[\"model\",\"clientTokens\"]],[24,[\"model\",\"currentTokens\"]],[24,[\"model\",\"traderTokens\"]],[28,\"route-action\",[\"switchAccount\"],null]]]],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nadex-web-app/templates/account/alerts/balances/profile/switch-account.hbs"
    }
  });
  _exports.default = _default;
});