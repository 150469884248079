define("nadex-web-app/templates/components/platform-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ELltO6P5",
    "block": "{\"symbols\":[\"@isDesktop\",\"@goToStatusPage\",\"@status\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"account_status \",[28,\"if\",[[23,1,[]],\"account_status--desktop\"],null]]]],[10,\"role\",\"button\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],[23,2,[]]],null]],[8],[0,\"\\n\"],[4,\"if\",[[23,1,[]]],null,{\"statements\":[[0,\"    \"],[7,\"h4\",true],[10,\"class\",\"account_status-label\"],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"account.platform-status.title\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"div\",true],[10,\"class\",\"account_status-value\"],[8],[0,\"\\n\"],[4,\"if\",[[28,\"or\",[[23,1,[]],[23,3,[]]],null]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[11,\"class\",[29,[\"account_status-badge \",[22,\"badgeClass\"]]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[1,[28,\"t\",[[24,[\"statusLabel\"]]],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nadex-web-app/templates/components/platform-status.hbs"
    }
  });
  _exports.default = _default;
});