define("nadex-web-app/templates/components/numeric-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "krMhYlPs",
    "block": "{\"symbols\":[],\"statements\":[],\"hasEval\":false}",
    "meta": {
      "moduleName": "nadex-web-app/templates/components/numeric-input.hbs"
    }
  });
  _exports.default = _default;
});