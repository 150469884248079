define("nadex-web-app/helpers/time-until", ["exports", "shared-components/helpers/time-until"], function (_exports, _timeUntil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _timeUntil.default;
    }
  });
  Object.defineProperty(_exports, "timeUntil", {
    enumerable: true,
    get: function get() {
      return _timeUntil.timeUntil;
    }
  });
});