define("nadex-web-app/utils/events", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.checkIfEventAsset = checkIfEventAsset;
  function checkIfEventAsset(instrument, markets, tree) {
    var hierarchyId3 = instrument.hierarchyId3,
      instrumentTypeActual = instrument.instrumentTypeActual,
      instrumentType = instrument.instrumentType;
    var parents = markets.getParents(tree, hierarchyId3, instrumentTypeActual || instrumentType);
    if (!parents) {
      return false;
    }
    var asset = parents.asset;
    return asset.name.toLowerCase().includes('events');
  }
});