define("nadex-web-app/helpers/extract-spread-market-price", ["exports", "nadex-web-app/utils/instrument-name-to-details"], function (_exports, _instrumentNameToDetails) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.extractSpreadMarketPrice = extractSpreadMarketPrice;
  function extractSpreadMarketPrice(params, _ref) {
    var instrumentName = _ref.instrumentName;
    if ((0, _instrumentNameToDetails.marketHasPeriodAndTime)(instrumentName)) {
      var _getSpreadWithPeriodP = (0, _instrumentNameToDetails.getSpreadWithPeriodPriceAndTime)(instrumentName),
        price = _getSpreadWithPeriodP.price;
      return price;
    } else {
      var _getSpreadDetailsWith = (0, _instrumentNameToDetails.getSpreadDetailsWithNoPeriod)(instrumentName),
        _price = _getSpreadDetailsWith.price;
      return _price;
    }
  }
  var _default = Ember.Helper.helper(extractSpreadMarketPrice);
  _exports.default = _default;
});