define("nadex-web-app/components/no-us-banks", ["exports", "shared-components/components/no-us-banks"], function (_exports, _noUsBanks) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _noUsBanks.default;
    }
  });
});