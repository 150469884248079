define("nadex-web-app/templates/account/alerts/messages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "QtPygK26",
    "block": "{\"symbols\":[\"message\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"page-stack\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"page card-holder profile\"],[8],[0,\"\\n    \"],[5,\"link-to\",[[12,\"class\",\"page_back\"]],[[\"@route\"],[\"account.alerts.balances.home\"]],{\"statements\":[[0,\"\\n      \"],[1,[28,\"t\",[\"home.home\"],null],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"card profile_settings\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"card_header\"],[8],[0,\"\\n        \"],[1,[28,\"t\",[\"welcome-message.messages\"],null],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"card_body\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"model\"]]],null,{\"statements\":[[0,\"          \"],[5,\"link-to\",[[12,\"class\",\"row card_route-link welcome-message_list-item\"]],[[\"@route\",\"@model\"],[\"account.alerts.messages.message\",[23,1,[\"id\"]]]],{\"statements\":[[0,\"\\n            \"],[1,[23,1,[\"head\"]],false],[0,\"\\n          \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[1,[22,\"outlet\"],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nadex-web-app/templates/account/alerts/messages.hbs"
    }
  });
  _exports.default = _default;
});