define("nadex-web-app/mixins/history-loader", ["exports", "nadex-web-app/services/lightstreamer", "moment"], function (_exports, _lightstreamer, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  var _default = Ember.Mixin.create({
    lightstreamer: Ember.inject.service(),
    session: Ember.inject.service(),
    activitySubscription: null,
    deactivate: function deactivate() {
      this._super.apply(this, arguments);
      this.activitySubscription.unsubscribe();
    },
    subscribeToActivity: function subscribeToActivity(activities) {
      var lightstreamer = this.lightstreamer;
      var currentAccountId = Ember.get(this, 'session.data.authenticated.currentAccountId');
      this.activitySubscription = lightstreamer.subscribe("V2-M-MESSAGE_EVENT_HANDLER|".concat(currentAccountId, "-OH-JSON"), ['json'], 'RAW', Ember.run.bind(this, this._handleHistoryUpdate, activities));
      Ember.set(this, 'firstUpdate', true);
    },
    _handleHistoryUpdate: function _handleHistoryUpdate(activities, itemUpdate) {
      var ohu = (0, _lightstreamer.extractMessageAsJson)(itemUpdate, 'json', 'OHU');
      if (!ohu || !ohu.header) {
        return;
      }

      /* istanbul ignore else */
      if (this.firstUpdate) {
        var dateNow = (0, _moment.default)(this.lightstreamer.msHeartbeat);
        var transactionDate = (0, _moment.default)(ohu.body.date, 'MM/DD/YY HH:mm');
        if (dateNow.diff(transactionDate) > 86400000) {
          return;
        }
        Ember.set(this, 'firstUpdate', false);
      }

      /* istanbul ignore else */
      if (ohu.header.contentType === 'OrderActivityAdd') {
        if (activities.findBy('result', ohu.body.result) || ohu.body.dealId && activities.findBy('dealId', ohu.body.dealId)) {
          return;
        }
        var workspaceModel = this.modelFor('account.alerts.balances.workspace');
        var shouldAnimate = workspaceModel ? workspaceModel.panes.monitor.selectedTab === 'closed' : false;
        activities.pushObject(_objectSpread({
          shouldAnimate: shouldAnimate
        }, ohu.body));
      }
    }
  });
  _exports.default = _default;
});