define("nadex-web-app/templates/account/alerts/balances/assets/browse/markets/market", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "G9xNIzFU",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\",true],[10,\"class\",\"page page--stay card-holder markets\"],[8],[0,\"\\n  \"],[5,\"market-list\",[],[[\"@assetName\",\"@expandedItem\",\"@lightstreamer\",\"@marketTask\",\"@market\",\"@onBack\",\"@onSelectExpiry\",\"@onOpenExpiries\",\"@onOpenTicket\",\"@underlyings\",\"@parent\"],[[24,[\"model\",\"assetName\"]],[24,[\"model\",\"expandedItem\"]],[24,[\"model\",\"lightstreamer\"]],[24,[\"model\",\"marketTask\"]],[24,[\"model\",\"market\"]],[28,\"route-action\",[\"transitionToAction\",[24,[\"model\",\"exitRoute\"]]],null],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"model\",\"expandedItem\"]]],null]],null],[28,\"route-action\",[\"transitionToAction\",\"account.alerts.balances.assets.browse.markets.market.expiries\",[28,\"lowercase\",[[24,[\"model\",\"parent\",\"instrumentType\"]]],null],[24,[\"model\",\"parent\",\"assetId\"]],[24,[\"model\",\"market\",\"marketId\"]]],null],[28,\"route-action\",[\"openTicket\",\"account.alerts.balances.assets.browse.markets.market.ticket\"],null],[24,[\"model\",\"underlyings\"]],[24,[\"model\",\"parent\"]]]]],[0,\"\\n\"],[9],[0,\"\\n\"],[1,[22,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nadex-web-app/templates/account/alerts/balances/assets/browse/markets/market.hbs"
    }
  });
  _exports.default = _default;
});