define("nadex-web-app/templates/account/alerts/balances/profile/display-settings/theme", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "X2VhlgEn",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"page-stack\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"page card-holder trade-settings\"],[8],[0,\"\\n    \"],[5,\"link-to\",[[12,\"class\",\"page_back\"]],[[\"@route\"],[\"account.alerts.balances.profile.display-settings.index\"]],{\"statements\":[[0,\"\\n      \"],[1,[28,\"t\",[\"profile.display-settings\"],null],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n    \"],[5,\"theme-setting-form\",[],[[\"@onSubmit\",\"@activeTheme\"],[[28,\"route-action\",[\"updateActiveTheme\"],null],[28,\"get\",[[24,[\"model\"]],\"activeTheme\"],null]]]],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nadex-web-app/templates/account/alerts/balances/profile/display-settings/theme.hbs"
    }
  });
  _exports.default = _default;
});