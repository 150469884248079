define("nadex-web-app/components/modal-box", ["exports", "shared-components/components/modal-box"], function (_exports, _modalBox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _modalBox.default;
    }
  });
});