define("nadex-web-app/templates/account/verification/progress", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "2rmUWadw",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"page-stack\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"page card-holder\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"card\"],[8],[0,\"\\n      \"],[5,\"link-to\",[[12,\"class\",\"page_back\"]],[[\"@route\"],[\"account.verification\"]],{\"statements\":[[1,[28,\"t\",[\"verification.back\"],null],false]],\"parameters\":[]}],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"card_body doc-upload doc-upload_progress\"],[8],[0,\"\\n        \"],[5,\"doc-upload-icon\",[[12,\"class\",\"doc-upload_progress-review-icon\"]],[[\"@name\",\"@status\"],[[24,[\"model\",\"type\"]],[24,[\"model\",\"status\"]]]]],[0,\"\\n        \"],[7,\"p\",true],[10,\"class\",\"doc-upload_progress-status\"],[8],[0,\"\\n          \"],[1,[28,\"t\",[\"docUpload.reject.title\"],null],false],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"p\",true],[10,\"class\",\"card_text doc-upload_progress-text\"],[8],[0,\"\\n          \"],[1,[28,\"t\",[\"docUpload.reject.steps-1\"],null],false],[0,\" \"],[1,[28,\"t\",[\"docUpload.reject.steps-2\"],null],false],[0,\" \"],[7,\"a\",true],[10,\"href\",\"mailto:accountopening@nadex.com\"],[10,\"target\",\"_top\"],[8],[1,[24,[\"model\",\"support\",\"supportEmail\"]],false],[9],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"doc-upload_review-buttons doc-upload_review-done\"],[8],[0,\"\\n          \"],[5,\"link-to\",[[12,\"class\",\"btn btn--neutral doc-upload_progress-btn\"]],[[\"@route\",\"@tagName\"],[[28,\"concat\",[\"account.verification.\",[24,[\"model\",\"type\"]]],null],\"button\"]],{\"statements\":[[0,\"\\n            \"],[1,[28,\"t\",[\"docUpload.reject.try-again\"],null],false],[0,\"\\n          \"]],\"parameters\":[]}],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[1,[22,\"outlet\"],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nadex-web-app/templates/account/verification/progress.hbs"
    }
  });
  _exports.default = _default;
});