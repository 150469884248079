define("nadex-web-app/components/accordion-details", ["exports", "ember-diff-attrs"], function (_exports, _emberDiffAttrs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['accordion-body'],
    classNameBindings: ['isCollapsed:accordion-body--collapsed', 'isExpanded:accordion-body--expanded'],
    shouldRender: false,
    didReceiveAttrs: (0, _emberDiffAttrs.default)('isExpanded', function (changedAttrs) {
      for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }
      this._super.apply(this, args);
      var isExpanded = Ember.get(this, 'isExpanded');
      if (isExpanded) {
        Ember.set(this, 'shouldRender', true);
      }
    }),
    animationEnd: function animationEnd(event) {
      if (event.animationName === 'accordion-slide-away') {
        Ember.set(this, 'shouldRender', false);
        var container = document.querySelector('.page.page--stay.card-holder');

        /* istanbul ignore if */
        if (!container || !container.scrollTo) {
          return;
        }
        container.scrollBy(0, 1);
      } else if (event.animationName === 'accordion-slide-in') {
        this.scrollNode(event.target);
      }
    },
    scrollNode: function scrollNode(target) {
      var container = document.querySelector('.page:last-of-type.card-holder');

      /* istanbul ignore if */
      if (!container || !container.scrollTo) {
        return;
      }
      var getScrollPosition = Ember.get(this, 'getScrollPosition');
      var scrollToIndex = getScrollPosition ? getScrollPosition(Ember.get(this, 'item')) : /* istanbul ignore next */0;
      container.scrollTo({
        top: target.offsetTop - 260 + scrollToIndex,
        behavior: 'smooth'
      });
    }
  });
  _exports.default = _default;
});