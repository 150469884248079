define("nadex-web-app/templates/account/alerts/deposit/wire-transfer/location", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "jsJe8wia",
    "block": "{\"symbols\":[],\"statements\":[[5,\"wire-transfer-info\",[],[[\"@location\",\"@officeAddress\"],[[24,[\"model\",\"location\"]],[24,[\"model\",\"officeAddress\"]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nadex-web-app/templates/account/alerts/deposit/wire-transfer/location.hbs"
    }
  });
  _exports.default = _default;
});