define("nadex-web-app/templates/components/route-error-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "BkvUvb4A",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"isOnline\"]]],null,{\"statements\":[[0,\"  \"],[7,\"h2\",true],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"error.header\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"h3\",true],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"error.sub-header\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"h2\",true],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"error.no-connection\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"h3\",true],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"error.check-connection\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nadex-web-app/templates/components/route-error-message.hbs"
    }
  });
  _exports.default = _default;
});