define("nadex-web-app/components/addresses-card-toggle", ["exports", "shared-components/components/addresses-card-toggle"], function (_exports, _addressesCardToggle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _addressesCardToggle.default;
    }
  });
});