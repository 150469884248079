define("nadex-web-app/components/profit-target", ["exports", "moment", "nadex-web-app/utils/closing-price", "nadex-web-app/utils/market-is-closed"], function (_exports, _moment, _closingPrice, _marketIsClosed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    lightstreamer: Ember.inject.service(),
    attributeBindings: ['novalidate'],
    classNames: ['form', 'ticket_form'],
    tagName: 'form',
    novalidate: true,
    isMobile: Ember.inject.service(),
    marketIsClosed: Ember.computed('instrument.updates.marketStatus', function () {
      return (0, _marketIsClosed.marketIsClosed)(this.instrument);
    }),
    expired: Ember.computed('lightstreamer.msHeartbeat', function () {
      var now = (0, _moment.default)(Number(Ember.get(this, 'lightstreamer.msHeartbeat')));
      var expirationDate = (0, _moment.default)(Number(Ember.get(this, 'instrument.lastTradeDateTime')));
      return expirationDate.diff(now) <= 0;
    }),
    disabled: Ember.computed('submitProfitTargetTicket.isRunning', 'errors', 'expired', 'direction', 'marketIsClosed', 'price', 'size', 'instrument.{maxPrice,increment}', 'instrument.updates.{displayBid,displayOffer}', function () {
      return Ember.get(this, 'errors.size') || Ember.get(this, 'errors.price') || this.expired || this.marketIsClosed || !this.size || this.profitTarget === '-' || Ember.get(this, 'submitProfitTargetTicket.isRunning') || !Ember.get(this, 'instrument.maxPrice') || !Ember.get(this, 'instrument.increment');
    }),
    minProfitTarget: Ember.computed.alias('instrument.increment'),
    minClosingPrice: Ember.computed('averagePrice', 'direction', 'instrument.{floor,increment}', function () {
      return (0, _closingPrice.minimumClosingPrice)(this.direction, this.averagePrice, this.instrument.increment, this.instrument.floor);
    }),
    maxClosingPrice: Ember.computed('averagePrice', 'direction', 'instrument.{ceiling,increment}', function () {
      return (0, _closingPrice.maximumClosingPrice)(this.direction, this.averagePrice, this.instrument.increment, this.instrument.ceiling);
    }),
    averagePrice: Ember.computed('position.position.openLevel', function () {
      var _this$position, _this$position$positi;
      return (_this$position = this.position) === null || _this$position === void 0 ? void 0 : (_this$position$positi = _this$position.position) === null || _this$position$positi === void 0 ? void 0 : _this$position$positi.openLevel;
    }),
    profitTarget: Ember.computed('averagePrice', 'direction', 'errors.{price,size}', 'instrument.increment', 'position.market.lotSize', 'price', 'size', function () {
      if (this.errors && (this.errors.price || this.errors.size)) {
        return '-';
      }
      if (this.price === 0) {
        return '-';
      }
      var coefficient = this.size * this.position.market.lotSize / this.instrument.increment;
      var profitTarget = this.direction === '+' ? (this.averagePrice - this.price) * coefficient : (this.price - this.averagePrice) * coefficient;
      if (isNaN(profitTarget) || profitTarget <= 0) {
        return '-';
      }
      return "$".concat(profitTarget.toFixed(2));
    }),
    showNoProfitError: Ember.computed('averagePrice', 'direction', 'price', 'size', function () {
      return this.isNoProfitTarget(this.averagePrice, this.direction, this.size);
    }),
    actions: {
      placeLimitOrder: function placeLimitOrder() {
        var props = this.getSubmitData();
        if (this.validate()) {
          this.submitProfitTargetTicket.perform(props);
        }
      }
    },
    getSubmitData: function getSubmitData() {
      var submitData = Ember.getProperties(this, 'direction', 'instrument', 'size', 'price');
      submitData.timeInForce = 'GoodTillCancelled';
      return submitData;
    },
    isNoProfitTarget: function isNoProfitTarget(averagePrice, direction, size) {
      var isNoBuyProfit = averagePrice === 0.25 && parseInt(size, 10) === 1 && direction === '+';
      var isNoSellProfit = averagePrice === 99.75 && parseInt(size, 10) === 1 && direction === '-';
      return isNoBuyProfit || isNoSellProfit;
    }
  });
  _exports.default = _default;
});