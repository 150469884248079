define("nadex-web-app/templates/components/no-documents-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "zYxpyTal",
    "block": "{\"symbols\":[\"@onClose\",\"@support\"],\"statements\":[[5,\"modal-box\",[],[[\"@title\",\"@onClose\"],[[28,\"t\",[\"docUpload.accepted-documents\"],null],[28,\"fn\",[[23,1,[]]],null]]],{\"statements\":[[0,\"\\n  \"],[7,\"p\",true],[10,\"class\",\"message-text\"],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"docUpload.accepted-1\"],null],false],[0,\"\\n    \"],[7,\"a\",true],[11,\"href\",[29,[\"mailto:\",[23,2,[\"accountOpeningEmail\"]]]]],[10,\"class\",\"tooltip_link\"],[8],[1,[23,2,[\"accountOpeningEmail\"]],false],[9],[0,\".\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"message-buttons\"],[8],[0,\"\\n    \"],[7,\"button\",true],[10,\"class\",\"btn btn--href message-btn\"],[11,\"onclick\",[28,\"fn\",[[23,1,[]]],null]],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"docUpload.close\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nadex-web-app/templates/components/no-documents-modal.hbs"
    }
  });
  _exports.default = _default;
});