define("nadex-web-app/templates/account/alerts/deposit/wire-transfer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "QMNXz4Tq",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"page card-holder wire-transfer\"],[8],[0,\"\\n  \"],[5,\"link-to\",[[12,\"class\",\"page_back\"]],[[\"@route\"],[\"account.alerts.deposit\"]],{\"statements\":[[0,\"\\n    \"],[1,[28,\"t\",[\"payments.add-funds\"],null],false],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n  \"],[7,\"ul\",true],[10,\"class\",\"sub-menu\"],[8],[0,\"\\n    \"],[7,\"li\",true],[10,\"class\",\"sub-menu_item\"],[8],[0,\"\\n      \"],[5,\"link-to\",[],[[\"@route\",\"@model\"],[\"account.alerts.deposit.wire-transfer.location\",\"us\"]],{\"statements\":[[0,\"\\n        \"],[1,[28,\"t\",[\"wire-transfer.us\"],null],false],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"li\",true],[10,\"class\",\"sub-menu_item\"],[8],[0,\"\\n      \"],[5,\"link-to\",[],[[\"@route\",\"@model\"],[\"account.alerts.deposit.wire-transfer.location\",\"non-us\"]],{\"statements\":[[0,\"\\n        \"],[1,[28,\"t\",[\"wire-transfer.non-us\"],null],false],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[1,[22,\"outlet\"],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nadex-web-app/templates/account/alerts/deposit/wire-transfer.hbs"
    }
  });
  _exports.default = _default;
});