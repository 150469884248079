define("nadex-web-app/templates/account/alerts/balances/assets/browse/markets/market/close-deal-ticket", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "TvA4hMX3",
    "block": "{\"symbols\":[],\"statements\":[[5,\"ember-wormhole\",[],[[\"@to\"],[\"ticket-wormhole\"]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[24,[\"model\",\"chartTicket\"]]],null,{\"statements\":[[0,\"    \"],[5,\"ticket-mask\",[],[[\"@touchStart\"],[[28,\"route-action\",[\"closeTicket\"],null]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[5,\"ticket-mask\",[],[[\"@click\"],[[28,\"route-action\",[\"closeTicket\"],null]]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[7,\"section\",true],[10,\"class\",\"ticket ticket--close-deal\"],[8],[0,\"\\n    \"],[5,\"close-deal-ticket\",[],[[\"@direction\",\"@errors\",\"@instrument\",\"@isInactiveUser\",\"@onClose\",\"@position\",\"@price\",\"@setTicketField\",\"@size\",\"@submitTicket\",\"@validate\"],[[24,[\"model\",\"direction\"]],[24,[\"model\",\"errors\"]],[24,[\"model\",\"instrument\"]],[24,[\"model\",\"isInactiveUser\"]],[28,\"route-action\",[\"closeTicket\"],null],[24,[\"model\",\"position\"]],[24,[\"model\",\"price\"]],[28,\"route-action\",[\"setTicketField\",[24,[\"model\"]]],null],[24,[\"model\",\"size\"]],[28,\"route-task\",[\"submitTicket\",\"CLOSE_DEAL\",\"mobile-ticket\"],null],[28,\"route-action\",[\"populateErrors\",[24,[\"model\"]],[24,[\"model\",\"position\",\"position\",\"dealSize\"]]],null]]]],[0,\"\\n  \"],[9],[0,\"\\n  \"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nadex-web-app/templates/account/alerts/balances/assets/browse/markets/market/close-deal-ticket.hbs"
    }
  });
  _exports.default = _default;
});