define("nadex-web-app/templates/components/error-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "x66NyREn",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"error_message\"],[8],[0,\"\\n  \"],[7,\"h2\",true],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"error.header\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"h3\",true],[10,\"class\",\"sub-header\"],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"error.sub-header\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",false],[12,\"class\",\"retry-btn\"],[12,\"role\",\"button\"],[3,\"action\",[[23,0,[]],\"refresh\"]],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"error.retry\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nadex-web-app/templates/components/error-message.hbs"
    }
  });
  _exports.default = _default;
});