define("nadex-web-app/helpers/extract-spread-market-name", ["exports", "nadex-web-app/utils/instrument-name-to-details"], function (_exports, _instrumentNameToDetails) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.extractSpreadMarketName = extractSpreadMarketName;
  function extractSpreadMarketName(params, _ref) {
    var instrumentName = _ref.instrumentName;
    if ((0, _instrumentNameToDetails.marketHasPeriodAndTime)(instrumentName)) {
      return (0, _instrumentNameToDetails.getSpreadNameWithPeriod)(instrumentName);
    } else {
      var _getSpreadDetailsWith = (0, _instrumentNameToDetails.getSpreadDetailsWithNoPeriod)(instrumentName),
        marketName = _getSpreadDetailsWith.marketName;
      return marketName;
    }
  }
  var _default = Ember.Helper.helper(extractSpreadMarketName);
  _exports.default = _default;
});