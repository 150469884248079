define("nadex-web-app/templates/account/verification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Hfbgioc5",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"page-stack\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"page card-holder doc-upload\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"card card--conjoined\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"card_header doc-upload_header\"],[8],[0,\"\\n        \"],[7,\"span\",true],[10,\"class\",\"doc-upload_title\"],[8],[0,\"\\n          \"],[1,[28,\"t\",[\"docUpload.upload\"],null],false],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"span\",false],[12,\"class\",\"doc-upload_close\"],[12,\"role\",\"button\"],[3,\"action\",[[23,0,[]],[28,\"route-action\",[\"closeDocUpload\"],null]]],[8],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[5,\"doc-upload-card\",[],[[\"@documentUploadInfo\",\"@rootPath\",\"@secureText\"],[[24,[\"model\",\"userData\",\"documentUploadInfo\"]],\"account.verification\",[28,\"t\",[\"docUpload.secure-short\"],null]]]],[0,\"\\n  \"],[9],[0,\"\\n  \"],[1,[22,\"outlet\"],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nadex-web-app/templates/account/verification.hbs"
    }
  });
  _exports.default = _default;
});