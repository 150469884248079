define("nadex-web-app/utils/invert-direction", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.invertDirection = void 0;
  /**
   * Returns the opposite direction symbol.
   * '+' returns '-'.
   * @param {String} direction
   */
  var invertDirection = function invertDirection(direction) {
    return direction === '+' ? '-' : '+';
  };
  _exports.invertDirection = invertDirection;
});