define("nadex-web-app/templates/components/accordion-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "q3sxEcwY",
    "block": "{\"symbols\":[\"item\",\"&default\"],\"statements\":[[4,\"each\",[[24,[\"items\"]]],null,{\"statements\":[[0,\"  \"],[14,2,[[23,1,[]],[28,\"hash\",null,[[\"header\",\"body\"],[[28,\"component\",[\"accordion-header\"],null],[28,\"component\",[\"accordion-details\"],[[\"isExpanded\",\"isCollapsed\",\"getScrollPosition\",\"item\"],[[28,\"eq\",[[24,[\"_expandingItem\"]],[23,1,[]]],null],[28,\"eq\",[[24,[\"_collapsingItem\"]],[23,1,[]]],null],[24,[\"getScrollPosition\"]],[23,1,[]]]]]]]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nadex-web-app/templates/components/accordion-group.hbs"
    }
  });
  _exports.default = _default;
});