define("nadex-web-app/templates/components/history-date-time", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "N4eCiUIz",
    "block": "{\"symbols\":[\"@activity\"],\"statements\":[[4,\"if\",[[23,1,[\"time\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[10,\"class\",\"date\"],[8],[0,\"\\n    \"],[1,[23,1,[\"date\"]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"span\",true],[10,\"class\",\"time\"],[8],[0,\"\\n    \"],[1,[28,\"format-time\",[[28,\"concat\",[[23,1,[\"date\"]],\" \",[23,1,[\"time\"]]],null]],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"span\",true],[10,\"class\",\"date\"],[8],[0,\"\\n    \"],[1,[28,\"cut-date-time\",[[23,1,[\"date\"]],\"date\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"span\",true],[10,\"class\",\"time\"],[8],[0,\"\\n    \"],[1,[28,\"format-time\",[[23,1,[\"date\"]]],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nadex-web-app/templates/components/history-date-time.hbs"
    }
  });
  _exports.default = _default;
});