define("nadex-web-app/templates/account/alerts/balances/profile/feedback", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "m6ynQJIh",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"page-stack\\n    \",[28,\"if\",[[28,\"get\",[[28,\"route-task\",[\"postFeedback\"],null],\"isRunning\"],null],\"page--loading-modal\"],null]]]],[8],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[\"page card-holder\\n      \",[28,\"if\",[[28,\"get\",[[28,\"route-task\",[\"postFeedback\"],null],\"isRunning\"],null],\"page--loading\"],null]]]],[8],[0,\"\\n    \"],[5,\"link-to\",[[12,\"class\",\"page_back\"]],[[\"@route\"],[\"account.alerts.balances.profile\"]],{\"statements\":[[0,\"\\n      \"],[7,\"div\",true],[8],[0,\"\\n        \"],[1,[28,\"t\",[\"profile.account\"],null],false],[0,\"\\n      \"],[9],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n    \"],[5,\"feedback\",[],[[\"@onSubmit\"],[[28,\"perform\",[[28,\"route-task\",[\"postFeedback\"],null]],null]]]],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nadex-web-app/templates/account/alerts/balances/profile/feedback.hbs"
    }
  });
  _exports.default = _default;
});