define("nadex-web-app/utils/with-run", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = withRun;
  function withRun(type, name, _ref) {
    var value = _ref.value;
    /* istanbul ignore next */
    return function () {
      var _Ember;
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }
      return (_Ember = Ember).run.apply(_Ember, [this, value].concat(args));
    };
  }
});