define("nadex-web-app/templates/account/alerts/balances/assets/browse/markets", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "MoURp621",
    "block": "{\"symbols\":[\"node\"],\"statements\":[[4,\"if\",[[24,[\"model\",\"showContent\"]]],null,{\"statements\":[[0,\"  \"],[7,\"section\",true],[10,\"class\",\"page card-holder markets\"],[8],[0,\"\\n    \"],[5,\"link-to\",[[12,\"class\",\"market-list_back page_back\"]],[[\"@route\"],[\"account.alerts.balances.assets.browse\"]],{\"statements\":[[0,\"\\n      \"],[7,\"div\",true],[8],[0,\"\\n        \"],[1,[28,\"t\",[[28,\"concat\",[\"assets.\",[28,\"lowercase\",[[24,[\"model\",\"instrumentType\"]]],null]],null]],[[\"htmlSafe\"],[true]]],false],[0,\"\\n      \"],[9],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n    \"],[7,\"h1\",true],[10,\"class\",\"card markets_title\"],[8],[0,\"\\n      \"],[1,[24,[\"model\",\"name\"]],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"ul\",true],[10,\"class\",\"card markets-list\"],[8],[0,\"\\n\"],[4,\"each\",[[28,\"sort-markets-by-popularity\",[[24,[\"model\",\"name\"]],[24,[\"model\",\"children\"]]],null]],null,{\"statements\":[[0,\"        \"],[7,\"li\",true],[8],[0,\"\\n          \"],[5,\"link-to\",[[12,\"class\",\"markets_link card_route-link\"]],[[\"@route\",\"@models\"],[\"account.alerts.balances.assets.browse.markets.market\",[28,\"array\",[[24,[\"model\",\"id\"]],[23,1,[\"id\"]]],null]]],{\"statements\":[[0,\"\\n            \"],[1,[23,1,[\"name\"]],false],[0,\"\\n            \"],[7,\"div\",true],[10,\"class\",\"card_route-icon\"],[8],[9],[0,\"\\n          \"]],\"parameters\":[]}],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[1,[22,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nadex-web-app/templates/account/alerts/balances/assets/browse/markets.hbs"
    }
  });
  _exports.default = _default;
});