define("nadex-web-app/templates/account/alerts/balances/trades/closed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "T9kr9Rci",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\",true],[10,\"class\",\"page page--stay card-holder history\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"model\",\"activities\",\"length\"]]],null,{\"statements\":[[0,\"    \"],[5,\"history-list\",[],[[\"@activities\"],[[24,[\"model\",\"activities\"]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[5,\"empty-monitor\",[],[[\"@monitorType\"],[\"closed\"]]],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"],[1,[22,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nadex-web-app/templates/account/alerts/balances/trades/closed.hbs"
    }
  });
  _exports.default = _default;
});