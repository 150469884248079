define("nadex-web-app/utils/inject-script", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.injectScript = void 0;
  // Modified from
  // https://github.com/minutebase/ember-inject-script/blob/master/addon/utils/inject-script.js
  var injectScript = function injectScript(src, moduleName, _ref) {
    var _ref$type = _ref.type,
      type = _ref$type === void 0 ? 'module' : _ref$type,
      crossOrigin = _ref.crossOrigin;
    /* istanbul ignore next */
    return new Promise(function (resolve, reject) {
      var scriptId = "".concat(moduleName, "-injected-script");
      var alreadyInjected = !!document.getElementById(scriptId);
      if (alreadyInjected) {
        var scriptFile = type === 'global' ? window[moduleName] : window.require(moduleName);
        if (scriptFile) {
          return resolve(scriptFile);
        }
      }
      var script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = src;
      script.id = scriptId;
      script.onload = function () {
        var scriptFile = type === 'global' ? window[moduleName] : window.require(moduleName);
        resolve(scriptFile);
      };
      script.onerror = reject;
      if (crossOrigin) {
        script.crossOrigin = crossOrigin;
      }
      document.querySelector('.ember-application').appendChild(script);
    });
  };
  _exports.injectScript = injectScript;
});