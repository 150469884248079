define("nadex-web-app/templates/components/events-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "5tBIGGxB",
    "block": "{\"symbols\":[\"@instrumentName\"],\"statements\":[[7,\"section\",true],[10,\"class\",\"events-message\"],[8],[0,\"\\n  \"],[7,\"h2\",true],[10,\"class\",\"events-message-title\"],[8],[0,\"\\n    \"],[1,[28,\"extract-binary-market-name\",null,[[\"instrumentName\"],[[23,1,[]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"p\",true],[10,\"class\",\"events-message-text\"],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"workspace.events-message-text\"],null],false],[0,\"\\n    \"],[7,\"a\",true],[10,\"href\",\"https://www.nadex.com/learning/economic-events-to-trade-with-nadex/\"],[10,\"rel\",\"noreferrer noopener\"],[10,\"target\",\"_blank\"],[10,\"class\",\"empty-workspace_link\"],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"workspace.events-message-link\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nadex-web-app/templates/components/events-message.hbs"
    }
  });
  _exports.default = _default;
});