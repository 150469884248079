define("nadex-web-app/utils/get-preference", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getPreference;
  function getPreference(name, userData, defaultValue) {
    if (userData && userData.preferences && userData.preferences.length) {
      var pref = userData.preferences.findBy('name', name);
      if (pref && pref.value) {
        return pref.value;
      }
    }
    return defaultValue;
  }
});