define("nadex-web-app/helpers/date-from-moment", ["exports", "shared-components/helpers/date-from-moment"], function (_exports, _dateFromMoment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "dateFromMoment", {
    enumerable: true,
    get: function get() {
      return _dateFromMoment.dateFromMoment;
    }
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _dateFromMoment.default;
    }
  });
});