define("nadex-web-app/templates/components/empty-monitor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "fUOe4/Nz",
    "block": "{\"symbols\":[\"@workspace\",\"@monitorType\",\"&default\"],\"statements\":[[7,\"div\",true],[11,\"class\",[28,\"concat\",[\"empty-monitor_container\",[28,\"if\",[[23,1,[]],\"-workspace\"],null]],null]],[8],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[28,\"concat\",[\"empty-monitor_icon\",[28,\"if\",[[23,1,[]],\"-workspace\"],null],\" empty-monitor_icon--\",[23,2,[]]],null]],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"empty-monitor_text-container\"],[8],[0,\"\\n    \"],[7,\"div\",true],[11,\"class\",[28,\"concat\",[\"empty-monitor_header\",[28,\"if\",[[23,1,[]],\" empty-monitor_header-workspace\"],null]],null]],[8],[0,\"\\n      \"],[1,[28,\"t\",[[28,\"concat\",[[23,2,[]],\".header\"],null]],null],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[11,\"class\",[28,\"concat\",[\"empty-monitor_body\",[28,\"if\",[[23,1,[]],\" empty-monitor_body-workspace\"],null]],null]],[8],[0,\"\\n      \"],[1,[28,\"t\",[[28,\"concat\",[[23,2,[]],\".body\"],null]],null],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[14,3]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nadex-web-app/templates/components/empty-monitor.hbs"
    }
  });
  _exports.default = _default;
});