define("nadex-web-app/components/monitor-tab-counter", ["exports", "ember-diff-attrs"], function (_exports, _emberDiffAttrs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['monitor-count'],
    classNameBindings: ['positiveValue:monitor-count_positive-number', 'isHighlighted:highlighted-monitor-count', 'tripleDigits:monitor-count_triple-digits'],
    shouldAnimate: false,
    positiveValue: Ember.computed.gt('counter', 0),
    tripleDigits: Ember.computed.gt('counter', 99),
    isHighlighted: Ember.computed('counter', 'shouldAnimate', function () {
      return this.counter > 0 && this.shouldAnimate;
    }),
    init: function init() {
      this._super.apply(this, arguments);
    },
    didReceiveAttrs: (0, _emberDiffAttrs.default)('counter', function (changedAttrs) {
      var _this = this;
      for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }
      this._super.apply(this, args);
      if (changedAttrs && changedAttrs.counter) {
        Ember.set(this, 'shouldAnimate', false);
        window.requestAnimationFrame(function () {
          /* istanbul ignore next */
          if (_this.isDestroyed) {
            return;
          }
          Ember.set(_this, 'shouldAnimate', true);
        });
      }
    }),
    formattedCounter: Ember.computed('counter', function () {
      if (this.counter < 1) {
        return null;
      } else if (this.counter > 99) {
        return '99+';
      } else {
        return this.counter;
      }
    })
  });
  _exports.default = _default;
});