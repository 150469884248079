define("nadex-web-app/templates/components/pane-toggle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "1pKOfhM3",
    "block": "{\"symbols\":[\"@label\",\"&default\"],\"statements\":[[7,\"span\",true],[10,\"class\",\"pane-toggle_icon\"],[8],[9],[0,\"\\n\"],[4,\"if\",[[23,1,[]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[11,\"class\",[28,\"concat\",[\"pane-toggle_label \",[24,[\"labelPositionClassName\"]]],null]],[8],[1,[23,1,[]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[14,2]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nadex-web-app/templates/components/pane-toggle.hbs"
    }
  });
  _exports.default = _default;
});