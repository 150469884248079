define("nadex-web-app/utils/local-storage", ["exports", "idb-keyval", "nadex-web-app/utils/waitable-promise"], function (_exports, _idbKeyval, _waitablePromise) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.clearStorage = clearStorage;
  _exports.deleteItem = deleteItem;
  _exports.getItem = getItem;
  _exports.setItem = setItem;
  function clearStorage() {
    return (0, _waitablePromise.default)(_idbKeyval.default.clear());
  }
  function deleteItem(key) {
    return (0, _waitablePromise.default)(_idbKeyval.default.del(key));
  }
  function getItem(key) {
    return (0, _waitablePromise.default)(_idbKeyval.default.get(key).then(function (dbValue) {
      return dbValue;
    }));
  }
  function setItem(key, value) {
    return (0, _waitablePromise.default)(_idbKeyval.default.set(key, value));
  }
});