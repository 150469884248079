define("nadex-web-app/templates/deposit/wire-transfer", ["exports", "my-account/templates/deposit/wire-transfer"], function (_exports, _wireTransfer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _wireTransfer.default;
    }
  });
});