define("nadex-web-app/templates/account/alerts/balances/assets/browse", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "19EXdSMA",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\",true],[10,\"class\",\"browse page card-holder\"],[8],[0,\"\\n  \"],[5,\"product-tabs\",[],[[\"@productType\",\"@onSelect\",\"@showTestMarkets\"],[[24,[\"model\",\"assetType\"]],[28,\"route-action\",[\"transitionToAction\",\"account.alerts.balances.assets.browse\"],null],[24,[\"model\",\"showTestMarkets\"]]]]],[0,\"\\n  \"],[5,\"asset-list\",[],[[\"@assets\",\"@assetType\",\"@durations\",\"@exchangeStatus\",\"@onSelectAsset\"],[[24,[\"model\",\"assets\"]],[24,[\"model\",\"assetType\"]],[24,[\"model\",\"durations\"]],[24,[\"model\",\"checkExchangeStatus\",\"last\",\"value\"]],[28,\"route-action\",[\"onSelectAsset\"],null]]]],[0,\"\\n\"],[9],[0,\"\\n\"],[1,[22,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nadex-web-app/templates/account/alerts/balances/assets/browse.hbs"
    }
  });
  _exports.default = _default;
});