define("nadex-web-app/components/lease-acceptance-list", ["exports", "shared-components/components/lease-acceptance-list"], function (_exports, _leaseAcceptanceList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _leaseAcceptanceList.default;
    }
  });
});