define("nadex-web-app/utils/inject-style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.injectStyle = void 0;
  // Modified from
  // https://github.com/minutebase/ember-inject-script/blob/master/addon/utils/inject-script.js
  var injectStyle = function injectStyle(src) {
    /* istanbul ignore next */
    return new Promise(function (resolve, reject) {
      var styleId = 'injectedstyle';
      var alreadyInjected = !!document.getElementById(styleId);
      if (alreadyInjected) {
        resolve();
      } else {
        var style = document.createElement('link');
        style.type = 'text/css';
        style.rel = 'stylesheet';
        style.href = src;
        style.id = styleId;
        style.onerror = reject;
        style.crossOrigin = 'anonymous';
        document.querySelector('.ember-application').appendChild(style);
      }
    });
  };
  _exports.injectStyle = injectStyle;
});