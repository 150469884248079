define("nadex-web-app/utils/device-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isSafari = _exports.isIos = _exports.isIe = _exports.isFirefox = _exports.isChrome = _exports.isApple = void 0;
  var isApple = function isApple() {
    var platform = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : navigator.platform;
    return !window.MSStream && /iphone|ipod|ipad|Mac/gi.test(platform);
  };
  _exports.isApple = isApple;
  var isIos = function isIos() {
    var platform = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : navigator.platform;
    return !window.MSStream && /iphone|ipod|ipad/gi.test(platform);
  };
  _exports.isIos = isIos;
  var isSafari = function isSafari() {
    var userAgent = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : navigator.userAgent;
    var ua = userAgent.toLowerCase();
    return ua.includes('safari') && !ua.includes('chrome') && !ua.includes('crios');
  };
  _exports.isSafari = isSafari;
  var isIe = function isIe() {
    var userAgent = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : navigator.userAgent;
    return /MSIE|Trident/.test(userAgent); // https://stackoverflow.com/a/22551342
  };
  _exports.isIe = isIe;
  var isChrome = function isChrome() {
    var userAgent = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : navigator.userAgent;
    var vendor = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : navigator.vendor;
    return userAgent.toLowerCase().indexOf('chrome') > -1 && vendor.toLowerCase().indexOf('google inc') > -1;
  };
  _exports.isChrome = isChrome;
  var isFirefox = function isFirefox() {
    var userAgent = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : navigator.userAgent;
    return userAgent.toLowerCase().indexOf('firefox') > -1;
  };
  _exports.isFirefox = isFirefox;
});