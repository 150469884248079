define("nadex-web-app/utils/direction-symbol-to-word", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = symbolToWord;
  /**
   * When passed ticket direction in symbol format, returns the word equivalent.
   * 'buy' returns '+', 'sell' returns '-'.
   * Otherwise returns null, this case is necessary for directionless tickets.
   * @param {String|null} direction
   */
  function symbolToWord(direction) {
    if (direction === '+') {
      return 'buy';
    } else if (direction === '-') {
      return 'sell';
    } else {
      return null;
    }
  }
});