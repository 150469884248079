define("nadex-web-app/templates/components/balance-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ksDYu/zO",
    "block": "{\"symbols\":[\"balance\",\"@obfuscateBalances\",\"@isPhone\",\"@balances\",\"@showingEditor\"],\"statements\":[[4,\"if\",[[28,\"not-eq\",[[28,\"get\",[[28,\"extract-jwt\",[[24,[\"session\",\"data\",\"authenticated\",\"currentTokens\",\"accessToken\"]]],null],\"memberType\"],null],\"TRADER\"],null]],null,{\"statements\":[[4,\"each\",[[28,\"filter-by\",[\"isSelected\",[23,4,[]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"balance_item\"],[8],[0,\"\\n      \"],[7,\"h2\",true],[10,\"class\",\"balance_value\"],[8],[0,\"\\n\"],[4,\"if\",[[23,2,[]]],null,{\"statements\":[[0,\"          $∗∗∗∗\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[1,[28,\"format-money\",[[28,\"get\",[[23,0,[\"accountNumbers\"]],[23,1,[\"key\"]]],null]],[[\"symbol\",\"thousand\",\"precision\"],[\"$\",\",\",2]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"      \"],[9],[0,\"\\n\"],[4,\"if\",[[23,3,[]]],null,{\"statements\":[[0,\"        \"],[7,\"h4\",true],[10,\"class\",\"balance_label balance_label--abbrev\"],[8],[0,\"\\n          \"],[1,[28,\"t\",[[28,\"concat\",[\"balances.abbrev-\",[23,1,[\"key\"]]],null]],[[\"default\"],[[28,\"concat\",[\"balances.\",[23,1,[\"key\"]]],null]]]],false],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[7,\"h4\",true],[10,\"class\",\"balance_label\"],[8],[0,\"\\n        \"],[1,[28,\"t\",[[28,\"concat\",[\"balances.\",[23,1,[\"key\"]]],null]],null],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[7,\"button\",true],[11,\"class\",[29,[\"balance_toggle \",[28,\"if\",[[23,5,[]],\"balance_toggle--up\",\"balance_toggle--down\"],null]]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nadex-web-app/templates/components/balance-bar.hbs"
    }
  });
  _exports.default = _default;
});