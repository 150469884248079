define("nadex-web-app/components/doughnut-chart", ["exports", "shared-components/components/doughnut-chart"], function (_exports, _doughnutChart) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _doughnutChart.default;
    }
  });
});