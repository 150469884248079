define("nadex-web-app/templates/components/docupload-camera-check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "7qG3ONoj",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"showSignatureCheck\"]]],null,{\"statements\":[[0,\"  \"],[5,\"lease-acceptance-list\",[],[[\"@showFooter\",\"@title\"],[false,[28,\"t\",[\"docUpload.document-must-show\"],null]]],{\"statements\":[[0,\"\\n    \"],[7,\"p\",true],[10,\"class\",\"doc-upload-acceptance_title\"],[8],[1,[28,\"t\",[\"docUpload.another-document\"],null],false],[9],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"p\",true],[10,\"class\",\"card_text doc-upload_review--center\"],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"docUpload.check-instructions\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nadex-web-app/templates/components/docupload-camera-check.hbs"
    }
  });
  _exports.default = _default;
});