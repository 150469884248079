define("nadex-web-app/components/market-view-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: 'section',
    classNames: ['market-view-header'],
    animateMarketViewMenu: false,
    actions: {
      toggleMarketViewMenu: function toggleMarketViewMenu() {
        Ember.set(this, 'animateMarketViewMenu', !this.animateMarketViewMenu);
        if (this.animateMarketViewMenu) {
          Ember.set(this, 'showMarketViewMenu', !this.showMarketViewMenu);
        }
      }
    }
  });
  _exports.default = _default;
});