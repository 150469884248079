define("nadex-web-app/components/payment-overview", ["exports", "shared-components/components/payment-overview"], function (_exports, _paymentOverview) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _paymentOverview.default;
    }
  });
});