define("nadex-web-app/templates/components/product-tabs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "yWFKfXwv",
    "block": "{\"symbols\":[\"@productType\",\"@onSelect\",\"@showTestMarkets\"],\"statements\":[[7,\"li\",true],[10,\"class\",\"sub-menu_item\"],[8],[0,\"\\n  \"],[7,\"a\",true],[11,\"class\",[28,\"if\",[[28,\"eq\",[[23,1,[]],\"binary\"],null],\"active\"],null]],[10,\"role\",\"button\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],[23,2,[]],\"binary\"],null]],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"assets.binary\"],[[\"htmlSafe\"],[true]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"li\",true],[10,\"class\",\"sub-menu_item\"],[8],[0,\"\\n  \"],[7,\"a\",true],[11,\"class\",[28,\"if\",[[28,\"eq\",[[23,1,[]],\"spread\"],null],\"active\"],null]],[10,\"role\",\"button\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],[23,2,[]],\"spread\"],null]],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"assets.spread\"],[[\"htmlSafe\"],[true]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"li\",true],[10,\"class\",\"sub-menu_item\"],[8],[0,\"\\n  \"],[7,\"a\",true],[11,\"class\",[28,\"if\",[[28,\"eq\",[[23,1,[]],\"bracket\"],null],\"active\"],null]],[10,\"role\",\"button\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],[23,2,[]],\"bracket\"],null]],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"assets.bracket\"],[[\"htmlSafe\"],[true]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"if\",[[23,3,[]]],null,{\"statements\":[[0,\"  \"],[7,\"li\",true],[10,\"class\",\"sub-menu_item\"],[8],[0,\"\\n    \"],[7,\"a\",true],[11,\"class\",[28,\"if\",[[28,\"eq\",[[23,1,[]],\"test\"],null],\"active\"],null]],[10,\"role\",\"button\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],[23,2,[]],\"test\"],null]],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"assets.test\"],[[\"htmlSafe\"],[true]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nadex-web-app/templates/components/product-tabs.hbs"
    }
  });
  _exports.default = _default;
});