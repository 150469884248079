define("nadex-web-app/services/app-measurement", ["exports", "ig-ember-app-measurement/services/app-measurement"], function (_exports, _appMeasurement) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _appMeasurement.default;
    }
  });
});