define("nadex-web-app/templates/components/group-by-instrument-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "3b1V/2zQ",
    "block": "{\"symbols\":[\"trades\",\"instrumentType\",\"&default\"],\"statements\":[[4,\"each\",[[28,\"-each-in\",[[24,[\"groupedTrades\"]]],null]],null,{\"statements\":[[0,\"  \"],[14,3,[[23,2,[]],[23,1,[]]]],[0,\"\\n\"]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nadex-web-app/templates/components/group-by-instrument-type.hbs"
    }
  });
  _exports.default = _default;
});