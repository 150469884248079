define("nadex-web-app/templates/components/monitor-tabs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "lkD6JQBk",
    "block": "{\"symbols\":[\"@selectedTab\",\"@onSelect\",\"@numOfOrders\",\"@numOfPositions\",\"@mobile\"],\"statements\":[[7,\"li\",true],[10,\"class\",\"sub-menu_item\"],[8],[0,\"\\n  \"],[7,\"a\",true],[10,\"role\",\"button\"],[11,\"class\",[28,\"if\",[[28,\"eq\",[[23,1,[]],\"orders\"],null],\"active\"],null]],[11,\"onclick\",[28,\"action\",[[23,0,[]],[23,2,[]],\"orders\"],null]],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"trades.orders\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[5,\"monitor-tab-counter\",[],[[\"@counter\"],[[23,3,[]]]],{\"statements\":[],\"parameters\":[]}],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"li\",true],[10,\"class\",\"sub-menu_item\"],[8],[0,\"\\n  \"],[7,\"a\",true],[10,\"role\",\"button\"],[11,\"class\",[28,\"if\",[[28,\"eq\",[[23,1,[]],\"positions\"],null],\"active\"],null]],[11,\"onclick\",[28,\"action\",[[23,0,[]],[23,2,[]],\"positions\"],null]],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"trades.positions\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[5,\"monitor-tab-counter\",[],[[\"@counter\"],[[23,4,[]]]],{\"statements\":[],\"parameters\":[]}],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"li\",true],[10,\"class\",\"sub-menu_item\"],[8],[0,\"\\n  \"],[7,\"a\",true],[10,\"role\",\"button\"],[11,\"class\",[28,\"if\",[[28,\"eq\",[[23,1,[]],\"closed\"],null],\"active\"],null]],[11,\"onclick\",[28,\"action\",[[23,0,[]],[23,2,[]],\"closed\"],null]],[8],[0,\"\\n\"],[4,\"if\",[[23,5,[]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"t\",[\"trades.history\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[28,\"t\",[\"trades.history24\"],[[\"htmlSafe\"],[true]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nadex-web-app/templates/components/monitor-tabs.hbs"
    }
  });
  _exports.default = _default;
});