define("nadex-web-app/components/order-type-setting-form", ["exports", "ember-diff-attrs"], function (_exports, _emberDiffAttrs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['card'],
    didReceiveAttrs: (0, _emberDiffAttrs.default)('defaultOrderType', function (changedAttrs) {
      for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }
      this._super.apply(this, args);
      var defaultOrderType = changedAttrs && changedAttrs.defaultOrderType ? changedAttrs.defaultOrderType.lastObject : this.defaultOrderType || 'MARKET_ORDER';
      Ember.set(this, '_defaultOrderType', defaultOrderType);
    })
  });
  _exports.default = _default;
});