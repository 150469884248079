define("nadex-web-app/mixins/orders-loader", ["exports", "nadex-web-app/helpers/is-bracket", "nadex-web-app/services/lightstreamer"], function (_exports, _isBracket, _lightstreamer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    lightstreamer: Ember.inject.service(),
    orders: Ember.inject.service(),
    session: Ember.inject.service(),
    marketsSubscriptions: null,
    orderChangeSubscriptions: null,
    deactivate: function deactivate() {
      this._super.apply(this, arguments);
      this.subscription.unsubscribe();
      Object.values(this.marketsSubscriptions).forEach(function (marketSubscription) {
        marketSubscription.unsubscribe();
      });
      Object.values(this.orderChangeSubscriptions).forEach(function (orderChangeSubscription) {
        orderChangeSubscription.unsubscribe();
      });
    },
    subscribeToOrders: function subscribeToOrders(orders) {
      Ember.set(this, 'subscription', this.subscribeToAddsAndDeletes(orders));
      Ember.set(this, 'orderChangeSubscriptions', orders.reduce(this.orders.subscribeToOrderChanges.bind(this), {}));
      Ember.set(this, 'marketsSubscriptions', orders.reduce(this.orders.subscribeToEpic.bind(this), {}));
    },
    normaliseOrderType: function normaliseOrderType(orders) {
      orders.map(function (order) {
        var isBracketInstrumentType = (0, _isBracket.isBracket)([order.market]);
        /* istanbul ignore else */
        if (!order.instrumentTypeActual) {
          if (isBracketInstrumentType) {
            Ember.set(order.market, 'instrumentTypeActual', 'Bracket');
          } else {
            Ember.set(order.market, 'instrumentTypeActual', order.market.instrumentType);
          }
        }
      });
    },
    subscribeToAddsAndDeletes: function subscribeToAddsAndDeletes(orders) {
      var currentAccountId = this.session.data.authenticated.currentAccountId;
      return this.lightstreamer.subscribe("V2-M-MESSAGE_EVENT_HANDLER|".concat(currentAccountId, "-WO-JSON"), ['json'], 'RAW', Ember.run.bind(this, this._handleAddOrDelete, orders));
    },
    _handleAddOrDelete: function _handleAddOrDelete(orders, itemUpdate) {
      var wou = (0, _lightstreamer.extractMessageAsJson)(itemUpdate, 'json', 'WOU');
      if (!wou || !wou.header) {
        return;
      }
      var dealId = wou.body.workingOrder.dealId;
      var existingOrder = orders.findBy('workingOrder.dealId', dealId);
      /* istanbul ignore else */
      if (wou.header.contentType === 'WorkingOrderDelete') {
        orders.removeObject(existingOrder);
        this._unsubscribeFromEpic(dealId);
        this._unsubscribeFromOrderChanges(dealId);
        if (!this.isMobile.any) {
          this.send('closeTicketOnOrderDelete', dealId);
        }
      } else if (wou.header.contentType === 'WorkingOrderAdd') {
        if (existingOrder) {
          return;
        }
        var market = wou.body.epic;
        market.underlyingEpic = market.permissionedUnderlyingEpic;
        var workingOrder = wou.body.workingOrder;
        market.underlyingEpic = market.permissionedUnderlyingEpic;
        if ((0, _isBracket.isBracket)([market])) {
          Ember.set(market, 'instrumentTypeActual', 'Bracket');
        }
        workingOrder.dma = workingOrder.DMA;
        delete workingOrder.DMA;
        var workspaceModel = this.modelFor('account.alerts.balances.workspace');
        var shouldAnimate = workspaceModel ? workspaceModel.panes.monitor.selectedTab === 'orders' : false;
        var order = {
          market: market,
          workingOrder: workingOrder,
          shouldAnimate: shouldAnimate
        };
        orders.pushObject(order);
        this.orders.subscribeToOrderChanges(this.orderChangeSubscriptions, order);
        this.orders.subscribeToEpic(this.marketsSubscriptions, order);
      }
    },
    _unsubscribeFromEpic: function _unsubscribeFromEpic(dealId) {
      var marketSubscriptions = this.marketsSubscriptions;
      var marketSubscription = Ember.get(marketSubscriptions, dealId);
      /* istanbul ignore else */
      if (marketSubscription) {
        marketSubscription.unsubscribe();
        delete marketSubscriptions[dealId];
      }
    },
    _unsubscribeFromOrderChanges: function _unsubscribeFromOrderChanges(dealId) {
      var orderChangeSubscription = Ember.get(this.orderChangeSubscriptions, dealId);
      /* istanbul ignore else */
      if (orderChangeSubscription) {
        orderChangeSubscription.unsubscribe();
        delete this.orderChangeSubscriptions[dealId];
      }
    }
  });
  _exports.default = _default;
});