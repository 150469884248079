define("nadex-web-app/templates/error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "4zuoHIyj",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"page error\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"error_message\"],[8],[0,\"\\n    \"],[5,\"route-error-message\",[],[[],[]]],[0,\"\\n    \"],[7,\"a\",true],[10,\"role\",\"button\"],[10,\"class\",\"tooltip_link\"],[11,\"onclick\",[28,\"route-action\",[\"reloadPage\"],null]],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"error.retry\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nadex-web-app/templates/error.hbs"
    }
  });
  _exports.default = _default;
});