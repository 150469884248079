define("nadex-web-app/templates/components/trade-question", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "djF6lUCA",
    "block": "{\"symbols\":[],\"statements\":[[1,[22,\"marketName\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nadex-web-app/templates/components/trade-question.hbs"
    }
  });
  _exports.default = _default;
});