define("nadex-web-app/utils/clipboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.copyToClipboard = copyToClipboard;
  function copyToClipboard(value) {
    var hiddenElement = document.createElement('textarea');
    hiddenElement.value = value;
    hiddenElement.style.position = 'absolute';
    hiddenElement.style.top = '-500px';
    document.body.appendChild(hiddenElement);
    hiddenElement.select();
    document.execCommand('copy');
    document.body.removeChild(hiddenElement);
  }
});