define("nadex-web-app/helpers/extract-spread-market-time", ["exports", "nadex-web-app/utils/instrument-name-to-details"], function (_exports, _instrumentNameToDetails) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.extractSpreadMarketTime = extractSpreadMarketTime;
  function extractSpreadMarketTime(params, _ref) {
    var instrumentName = _ref.instrumentName;
    if ((0, _instrumentNameToDetails.marketHasPeriodAndTime)(instrumentName)) {
      var _getSpreadWithPeriodP = (0, _instrumentNameToDetails.getSpreadWithPeriodPriceAndTime)(instrumentName),
        time = _getSpreadWithPeriodP.time;
      return time.replace(/\.[A-Z]{2}(?:\.\d+)?$/, '').toLowerCase();
    } else {
      var _getSpreadDetailsWith = (0, _instrumentNameToDetails.getSpreadDetailsWithNoPeriod)(instrumentName),
        _time = _getSpreadDetailsWith.time;
      return _time.replace(/\.[A-Z]{2}(?:\.\d+)?$/, '').toLowerCase();
    }
  }
  var _default = Ember.Helper.helper(extractSpreadMarketTime);
  _exports.default = _default;
});