define("nadex-web-app/utils/number", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.countDecimals = countDecimals;
  _exports.isValidIncrement = isValidIncrement;
  _exports.prettyPrint = prettyPrint;
  _exports.roundFloatDown = roundFloatDown;
  _exports.roundFloatUp = roundFloatUp;
  function roundFloatUp(value, toNearest, fixed) {
    return (Math.ceil(value / toNearest) * toNearest).toFixed(fixed);
  }
  function roundFloatDown(value, toNearest, fixed) {
    return (Math.floor(value / toNearest) * toNearest).toFixed(fixed);
  }
  function isValidIncrement(value, toNearest) {
    var decimalCount = Math.max(countDecimals(value), countDecimals(toNearest));
    var precision = Math.pow(10, decimalCount);
    var scaledValue = parseInt(value.toFixed(decimalCount).replace('.', ''));
    var scaledStep = parseInt(toNearest.toFixed(decimalCount).replace('.', ''));
    return !(scaledValue % scaledStep / precision);
  }
  function countDecimals(num) {
    var match = "".concat(num).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
    if (!match) {
      return 0;
    }
    return Math.max(0,
    // Number of digits right of decimal point.
    (match[1] ? match[1].length : 0
    // Adjust for scientific notation.
    ) - (match[2] ? +match[2] : 0));
  }
  function prettyPrint(value, decimalPlaces) {
    if (!value) {
      return;
    }
    if (!decimalPlaces || decimalPlaces < 2) {
      decimalPlaces = 2;
    }
    return (+value).toFixed(decimalPlaces);
  }
});