define("nadex-web-app/utils/create-chart-api", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = createChartApi;
  function createChartApi(session, lightstreamer, channel, factory) {
    var accountId = session.currentAccountId,
      cstToken = session.cst,
      currencyCode = session.currencyIsoCode,
      locale = session.clientLocale,
      currentTokens = session.currentTokens,
      xst = session.xst,
      webSiteId = session.webSiteId,
      webSiteType = session.siteType,
      domain = session.domain;
    var ssoToken = (currentTokens === null || currentTokens === void 0 ? void 0 : currentTokens.sso) || xst;
    var userInfo = {
      accountId: accountId,
      cstToken: cstToken,
      currencyCode: currencyCode,
      locale: locale,
      ssoToken: ssoToken,
      webSiteId: webSiteId,
      webSiteType: webSiteType
    };
    var deviceInfo = {
      applicationType: 'ig',
      clientPlatform: channel,
      clientVersion: '1.0.0',
      deviceType: window.navigator.platform,
      deviceVersion: 'unknown',
      touch: true
    };
    var hostname = window.location.hostname;
    var origin = hostname === 'localhost' && !domain.includes('mirage') ? /* istanbul ignore next */'' : domain;
    return factory && factory.create(userInfo, deviceInfo, {
      origin: origin
    }, lightstreamer);
  }
});