define("nadex-web-app/utils/instrument-name-to-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.buildSpreadPriceAndTime = buildSpreadPriceAndTime;
  _exports.getBinaryMarketName = getBinaryMarketName;
  _exports.getBinaryMarketNameWithSymbol = getBinaryMarketNameWithSymbol;
  _exports.getBinaryPrice = getBinaryPrice;
  _exports.getBinaryPriceAndTime = getBinaryPriceAndTime;
  _exports.getSpreadDetailsWithNoPeriod = getSpreadDetailsWithNoPeriod;
  _exports.getSpreadNameWithPeriod = getSpreadNameWithPeriod;
  _exports.getSpreadWithPeriodPriceAndTime = getSpreadWithPeriodPriceAndTime;
  _exports.marketHasPeriodAndTime = marketHasPeriodAndTime;
  _exports.parseInstrumentName = parseInstrumentName;
  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  function getBinaryMarketName(instrumentName) {
    return instrumentName.substring(0, instrumentName.indexOf('>')).trim();
  }
  function getBinaryMarketNameWithSymbol(instrumentName) {
    return instrumentName.substring(0, instrumentName.indexOf('>') + 1).trim();
  }
  function marketHasPeriodAndTime(instrumentName) {
    return (instrumentName.match(/\)/g) || []).length > 1;
  }
  function getBinaryPriceAndTime(instrumentName, showSymbol) {
    var priceAndTime = getBinaryPrice(instrumentName, showSymbol);
    var matches = priceAndTime.match(/(\W*[-.\d]+%?)(.*)/);
    /* istanbul ignore if */
    if (!matches) {
      return;
    }
    return {
      price: matches[1].trim(),
      time: matches[2].replace(/[()]/g, '').trim()
    };
  }
  function getBinaryPrice(instrumentName, showSymbol) {
    var price = instrumentName.substring(instrumentName.indexOf('>') - 1).trim();
    if (showSymbol) {
      return price;
    } else {
      return price.replace(/^[^\d-.]+/, '').replace('%', '').trim();
    }
  }
  function getSpreadNameWithPeriod(instrumentName) {
    return instrumentName.substring(0, instrumentName.indexOf(')') + 1);
  }
  function getSpreadWithPeriodPriceAndTime(instrumentName) {
    var marketName = instrumentName.substring(0, instrumentName.indexOf(')') + 1);
    var priceAndTime = buildSpreadPriceAndTime(instrumentName);
    var price = priceAndTime.substring(0, priceAndTime.indexOf(' ')).trim();
    var time = priceAndTime.substring(priceAndTime.indexOf(' ')).replace('(', '').replace(')', '').trim();
    return {
      marketName: marketName,
      price: price,
      time: time
    };
  }
  function getSpreadDetailsWithNoPeriod(instrumentName) {
    var marketNameAndPrice = instrumentName.substring(0, instrumentName.indexOf('(')).trim();
    var _instrumentName$match = instrumentName.match(/\d+/),
      _instrumentName$match2 = _slicedToArray(_instrumentName$match, 1),
      floor = _instrumentName$match2[0];
    var marketName = marketNameAndPrice.substring(0, instrumentName.indexOf(floor) - 1).trim();
    var price = marketNameAndPrice.substring(marketNameAndPrice.indexOf(floor) - 1).trim();
    var time = instrumentName.substring(instrumentName.indexOf('(')).replace('(', '').replace(')', '');
    return {
      marketName: marketName,
      price: price,
      time: time
    };
  }
  function buildSpreadPriceAndTime(instrumentName) {
    var priceAndTime = instrumentName.substring(instrumentName.indexOf(')') + 1).trim();
    if (priceAndTime.charAt(priceAndTime.lastIndexOf('-') + 1) === ' ') {
      var start = priceAndTime.substring(0, priceAndTime.lastIndexOf('-') + 1);
      var end = priceAndTime.substring(priceAndTime.lastIndexOf('-') + 1).trim();
      priceAndTime = start + end;
    }
    return priceAndTime;
  }
  function parseInstrumentName(instrumentName, type) {
    if (type === 'Binary') {
      var details;
      if (instrumentName.includes('AM') || instrumentName.includes('PM')) {
        details = getBinaryPriceAndTime(instrumentName);
      } else {
        details = {
          price: getBinaryPrice(instrumentName, false)
        };
      }
      details.marketName = getBinaryMarketName(instrumentName);
      return details;
    } else if (marketHasPeriodAndTime(instrumentName)) {
      return getSpreadWithPeriodPriceAndTime(instrumentName);
    } else {
      return getSpreadDetailsWithNoPeriod(instrumentName);
    }
  }
});