define("nadex-web-app/utils/env", ["exports", "shared-components/utils/env"], function (_exports, _env) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "ENVS", {
    enumerable: true,
    get: function get() {
      return _env.ENVS;
    }
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _env.default;
    }
  });
});