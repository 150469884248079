define("nadex-web-app/templates/entity-login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "st6lqVAl",
    "block": "{\"symbols\":[],\"statements\":[[4,\"unless\",[[24,[\"model\",\"switchingToCreateAccountForm\"]]],null,{\"statements\":[[0,\"  \"],[5,\"single-spa\",[[12,\"class\",\"page login entity-login\"]],[[\"@package\",\"@userAgent\",\"@onAuthenticated\",\"@entityFlow\"],[\"@nadex/login\",[28,\"readonly\",[[23,0,[\"model\",\"userAgent\"]]],null],[28,\"fn\",[[23,0,[\"handleAuthenticated\"]]],null],true]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nadex-web-app/templates/entity-login.hbs"
    }
  });
  _exports.default = _default;
});