define("nadex-web-app/utils/ticket-preference-formatters", ["exports", "nadex-web-app/utils/get-preference"], function (_exports, _getPreference) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.buildTolerances = buildTolerances;
  _exports.getDealSize = getDealSize;
  _exports.mapTimeInForce = mapTimeInForce;
  function buildTolerances(userData) {
    return {
      binaryMarketTolerance: {
        value: (0, _getPreference.default)('binaryMarketTolerance', userData)
      },
      spreadMarketTolerance: {
        value: (0, _getPreference.default)('spreadMarketTolerance', userData)
      }
    };
  }
  function getDealSize(userData) {
    return +(0, _getPreference.default)('preferenceDealSize', userData, '1');
  }
  function mapTimeInForce(userData) {
    return (0, _getPreference.default)('defaultOrderType', userData) === 'LIMIT_GTC' ? 'GoodTillCancelled' : 'ExecuteAndEliminate';
  }
});