define("nadex-web-app/services/fetch", ["exports", "fetch", "shared-components/utils/read-as-url", "nadex-web-app/utils/waitable-promise"], function (_exports, _fetch2, _readAsUrl, _waitablePromise) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.extractJson = extractJson;
  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  var _default = Ember.Service.extend({
    userAgent: Ember.inject.service(),
    session: Ember.inject.service(),
    rum: Ember.inject.service(),
    getHeaders: function getHeaders(session, useApiDomain) {
      var headers = {
        Accept: "application/json; charset=UTF-8",
        'Content-Type': 'application/json; charset=UTF-8'
      };
      headers['x-device-user-agent'] = this.userAgent.getUserAgent();
      if (session.authenticated) {
        var _session$authenticate;
        headers['X-SECURITY-TOKEN'] = ((_session$authenticate = session.authenticated.currentTokens) === null || _session$authenticate === void 0 ? void 0 : _session$authenticate.sso) || session.authenticated.xst;
      }
      // Requests to *api.nadex.com require the JWT token as an Authorization header
      if (useApiDomain && session.authenticated.currentTokens) {
        headers.Authorization = "Bearer ".concat(session.authenticated.currentTokens.accessToken);
      }
      return headers;
    },
    getUrl: function getUrl(url, session, useApiDomain) {
      var isCompleteUrl = /^https?:\/\//.test(url);
      var domain = useApiDomain ? session.authenticated.domain.replace('trade', 'api') : session.authenticated.domain;
      return isCompleteUrl ? url : "".concat(domain).concat(url);
    },
    ensureResponseAuthorized: function ensureResponseAuthorized(url, response) {
      var session = this.session;
      if (response.status === 401) {
        this.rum.recordLogoutEvent("401 unauthorized response from request: ".concat(url), "body: ".concat(response === null || response === void 0 ? void 0 : response.body, ", statusText: ").concat(response === null || response === void 0 ? void 0 : response.statusText));
        Ember.set(this.session, 'loggedOutDueToInactivity', true);
        session.invalidate();
      }
      return Ember.RSVP.resolve(response);
    },
    _fetch: function _fetch(url, body, method, headers, useApiDomain, jwt) {
      var session = this.session.data;
      var reqHeaders = url.includes('statuspage.io') ? headers : Object.assign(headers, {
        Authorization: "Bearer ".concat(jwt)
      });
      var completeUrl = this.getUrl(url, session, useApiDomain);
      return (0, _fetch2.default)(completeUrl, {
        body: body,
        headers: reqHeaders,
        method: method
      });
    },
    fetchWithoutJwt: function fetchWithoutJwt(url) {
      var body = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      var method = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'GET';
      var headers = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
      var useApiDomain = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
      var session = this.session.data;
      var requestHeaders = Object.assign(this.getHeaders(session, useApiDomain), headers);
      var isJsonRequest = requestHeaders.Accept.includes('application/json');
      var isPlainTextRequest = requestHeaders.Accept.includes('text/plain');
      return this._fetch(url, body, method, requestHeaders, useApiDomain).then(this.ensureResponseAuthorized.bind(this, url)).then(function (response) {
        if (isJsonRequest) {
          return extractJson(response);
        }
        if (isPlainTextRequest) {
          return extractText(response);
        }
        return extractBuffer(response);
      });
    },
    fetchWithJwt: function fetchWithJwt(url) {
      var _this = this;
      var body = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      var method = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'GET';
      var headers = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
      var useApiDomain = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
      var session = this.session.data;
      var requestHeaders = Object.assign(this.getHeaders(session, useApiDomain), headers);
      var isJsonRequest = requestHeaders.Accept.includes('application/json');
      var isPlainTextRequest = requestHeaders.Accept.includes('text/plain');
      var tokenOptions = {
        authorizationParams: {
          audience: 'api:auth:nadex',
          scope: 'offline_access read:funds read:memberData'
        },
        timeoutInSeconds: 180
      };
      return this.session.auth0Client.getTokenSilently(tokenOptions).then(this._fetch.bind(this, url, body, method, requestHeaders, useApiDomain)).then(this.ensureResponseAuthorized.bind(this, url)).then(function (response) {
        if (isJsonRequest) {
          return extractJson(response);
        }
        if (isPlainTextRequest) {
          return extractText(response);
        }
        return extractBuffer(response);
      }).catch(function (err) {
        if (['login_required', 'access_denied', 'missing_refresh_token'].includes(err.error)) {
          // TODO: test this
          _this.rum.recordLogoutEvent('invalid JWT when fetching', err.error);
          return _this.session.invalidate();
        }
        throw err;
      });
    },
    authorizedFetch: function authorizedFetch(url) {
      var body = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      var method = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'GET';
      var headers = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
      var useApiDomain = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
      var session = this.session.data;
      if (!session.authenticated) {
        this.rum.recordLogoutEvent("session not authenticated for authorized fetch() while requesting: ".concat(url)); // eslint-disable-line max-len
        session.invalidate();
        return Ember.RSVP.reject();
      }
      if (['https://demo-trade.nadex.com', 'https://mirage-trade.nadex.com'].includes(this.session.data.authenticated.domain)) {
        // eslint-disable-line max-len
        return this.fetchWithoutJwt(url, body, method, headers, useApiDomain);
      } else {
        return this.fetchWithJwt(url, body, method, headers, useApiDomain);
      }
    },
    elevatedFetch: function elevatedFetch(url) {
      var body = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      var method = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'GET';
      var headers = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
      var useApiDomain = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
      var session = this.session.data;
      if (!session.authenticated) {
        this.rum.recordLogoutEvent("session not authenticated for elevatedFetch() while requesting: ".concat(url)); // eslint-disable-line max-len
        session.invalidate();
        return Ember.RSVP.reject();
      }
      var elevatedAccessToken = session.authenticated.elevatedAccessToken;
      var requestHeaders = Object.assign(this.getHeaders(session), headers);
      var isJsonRequest = requestHeaders.Accept.includes('application/json');
      var isPlainTextRequest = requestHeaders.Accept.includes('text/plain');
      return this._fetch(url, body, method, requestHeaders, useApiDomain, elevatedAccessToken).then(function (response) {
        if (isJsonRequest) {
          return extractJson(response);
        }
        if (isPlainTextRequest) {
          return extractText(response);
        }
        return extractBuffer(response);
      });
    },
    imageUpload: function imageUpload(file, fileURL, url, data) {
      var session = this.session.data;
      var headers = Object.assign({}, this.getHeaders(session));
      // Content-type should not be set when using FormData see NW-3828
      delete headers['Content-Type'];
      var image = this.safeGetImage(file, fileURL, file.name);
      var formData = new FormData();
      formData.append('file', image);
      formData.append('name', file.name);
      Object.entries(data).forEach(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2),
          key = _ref2[0],
          value = _ref2[1];
        return formData.append(key, value);
      });
      return (0, _fetch2.default)("".concat(session.authenticated.domain).concat(url), {
        method: 'POST',
        headers: headers,
        body: formData
      }).then(this.ensureResponseAuthorized.bind(this, url)).then(extractJson).catch(this.ensureResponseAuthorized.bind(this, url));
    },
    // Some version of FF do not have `blob.lastModified` and error out
    // And iOS 11 does not like `File` so we cater for both
    safeGetImage: function safeGetImage(file, fileURL, name) {
      try {
        file.lastModified;
        return file;
      } catch (err) {
        var blob = (0, _readAsUrl.dataURItoBlob)(fileURL);
        return new File([blob], name, {
          lastModified: Date.now()
        });
      }
    }
  });
  _exports.default = _default;
  function extractJson(response) {
    if (!response.ok) {
      throw response;
    }
    var contentType = response.headers.get('content-type');
    return contentType.indexOf('application/json') !== -1 ? (0, _waitablePromise.default)(response.json()) : {};
  }
  function extractBuffer(response) {
    if (!response.ok) {
      throw response;
    }
    return (0, _waitablePromise.default)(response.arrayBuffer());
  }
  function extractText(response) {
    if (!response.ok) {
      throw response;
    }
    return (0, _waitablePromise.default)(response.text());
  }
});