define("nadex-web-app/helpers/extract-binary-market-time", ["exports", "nadex-web-app/utils/instrument-name-to-details"], function (_exports, _instrumentNameToDetails) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.extractBinaryMarketTime = extractBinaryMarketTime;
  function extractBinaryMarketTime(params, _ref) {
    var instrumentName = _ref.instrumentName;
    var _getBinaryPriceAndTim = (0, _instrumentNameToDetails.getBinaryPriceAndTime)(instrumentName),
      time = _getBinaryPriceAndTim.time;
    return time.toLowerCase();
  }
  var _default = Ember.Helper.helper(extractBinaryMarketTime);
  _exports.default = _default;
});