define("nadex-web-app/templates/account/alerts/messages/message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "os1619/+",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"page-stack\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"page card-holder\"],[8],[0,\"\\n    \"],[5,\"link-to\",[[12,\"class\",\"page_back\"]],[[\"@route\"],[[24,[\"model\",\"previousUrl\"]]]],{\"statements\":[[0,\"\\n      \"],[1,[24,[\"model\",\"backLabel\"]],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"welcome-message\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"welcome-message_text-header\"],[8],[0,\"\\n        \"],[1,[24,[\"model\",\"message\",\"head\"]],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"welcome-message_body\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"welcome-message_text\"],[8],[0,\"\\n          \"],[1,[24,[\"model\",\"message\",\"body\"]],false],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"footer\",true],[10,\"class\",\"welcome-message_footer\"],[8],[0,\"\\n        \"],[7,\"button\",true],[10,\"class\",\"btn btn--primary\"],[11,\"onclick\",[28,\"route-action\",[\"markAsRead\"],null]],[8],[0,\"\\n          \"],[1,[28,\"t\",[\"welcome-message.mark-as-read\"],null],false],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[1,[22,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nadex-web-app/templates/account/alerts/messages/message.hbs"
    }
  });
  _exports.default = _default;
});