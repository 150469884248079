define("nadex-web-app/templates/account/alerts/balances/assets/browse/markets/market/profit-target", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "d7jjtL7U",
    "block": "{\"symbols\":[\"@dismissConfirm\"],\"statements\":[[5,\"ember-wormhole\",[],[[\"@to\"],[\"ticket-wormhole\"]],{\"statements\":[[0,\"\\n  \"],[7,\"section\",true],[10,\"class\",\"ticket ticket--profit-target\"],[8],[0,\"\\n    \"],[5,\"profit-target\",[],[[\"@direction\",\"@dismissConfirm\",\"@errors\",\"@instrument\",\"@position\",\"@onClose\",\"@price\",\"@setTicketField\",\"@size\",\"@submitProfitTargetTicket\",\"@timeInForce\",\"@validate\"],[[24,[\"model\",\"direction\"]],[23,1,[]],[24,[\"model\",\"errors\"]],[24,[\"model\",\"instrument\"]],[24,[\"model\",\"position\"]],[28,\"route-action\",[\"closeTicket\"],null],[24,[\"model\",\"price\"]],[28,\"route-action\",[\"setTicketField\",[24,[\"model\"]]],null],[24,[\"model\",\"size\"]],[28,\"route-task\",[\"submitTicket\",\"PROFIT_TARGET\",\"profit-target\"],null],[24,[\"model\",\"timeInForce\"]],[28,\"route-action\",[\"populateErrors\",[24,[\"model\"]]],null]]]],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nadex-web-app/templates/account/alerts/balances/assets/browse/markets/market/profit-target.hbs"
    }
  });
  _exports.default = _default;
});