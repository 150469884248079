define("nadex-web-app/components/contract-size-setting-form", ["exports", "ember-diff-attrs"], function (_exports, _emberDiffAttrs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    classNames: ['card'],
    _contractSize: null,
    didReceiveAttrs: (0, _emberDiffAttrs.default)('contractSize', function (changedAttrs) {
      for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }
      this._super.apply(this, args);
      var contractSize = changedAttrs && changedAttrs.contractSize ? changedAttrs.contractSize.lastObject : this.contractSize || 1;
      Ember.set(this, '_contractSize', contractSize);
    }),
    validate: function validate(value) {
      return value > 1000 || value < 1 ? this.intl.t('profile.contract-sizes.error') : '';
    },
    actions: {
      submit: function submit() {
        var value = this._contractSize;
        var error = this.validate(value);
        Ember.set(this, 'error', error);
        if (!error) {
          this.onSubmit('preferenceDealSize', value);
        }
      }
    }
  });
  _exports.default = _default;
});