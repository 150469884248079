define("nadex-web-app/templates/account", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "zZ1kpZ9S",
    "block": "{\"symbols\":[\"infoMessage\"],\"statements\":[[4,\"each\",[[24,[\"model\",\"infoMessages\"]]],null,{\"statements\":[[0,\"  \"],[5,\"info-message\",[],[[\"@infoMessage\",\"@onClose\"],[[23,1,[]],[28,\"route-action\",[\"clearInfoMessage\"],null]]]],[0,\"\\n\"]],\"parameters\":[1]},null],[4,\"unless\",[[24,[\"model\",\"online\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"message-banner message-banner--error\"],[10,\"role\",\"button\"],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"error.offline\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[1,[22,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nadex-web-app/templates/account.hbs"
    }
  });
  _exports.default = _default;
});