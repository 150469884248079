define("nadex-web-app/templates/components/resizer-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "A2sX/+jS",
    "block": "{\"symbols\":[],\"statements\":[[7,\"button\",false],[12,\"class\",\"resizer-button resizer-button--vertical\"],[3,\"on\",[\"mousedown\",[28,\"action\",[[23,0,[]],[24,[\"initiateResizing\"]]],null]]],[3,\"on\",[\"dblclick\",[28,\"action\",[[23,0,[]],\"reset\"],null]]],[8],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nadex-web-app/templates/components/resizer-button.hbs"
    }
  });
  _exports.default = _default;
});