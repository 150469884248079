define("nadex-web-app/components/wire-transfer-info", ["exports", "shared-components/components/wire-transfer-info"], function (_exports, _wireTransferInfo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _wireTransferInfo.default;
    }
  });
});