define("nadex-web-app/templates/components/formatted-market-name", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "FNPWdBkW",
    "block": "{\"symbols\":[\"time\"],\"statements\":[[4,\"if\",[[28,\"eq\",[[24,[\"type\"]],\"Binary\"],null]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[10,\"class\",\"binary-name\"],[8],[0,\"\\n    \"],[1,[28,\"extract-binary-market-name\",null,[[\"instrumentName\"],[[24,[\"instrumentName\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"span\",true],[10,\"class\",\"binary-price\"],[8],[0,\"\\n    \"],[1,[28,\"extract-binary-market-price\",null,[[\"instrumentName\",\"showSymbol\"],[[24,[\"instrumentName\"]],true]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[4,\"with\",[[28,\"extract-binary-market-time\",null,[[\"instrumentName\"],[[24,[\"instrumentName\"]]]]]],null,{\"statements\":[[4,\"if\",[[24,[\"showTime\"]]],null,{\"statements\":[[0,\"      \"],[7,\"span\",true],[10,\"class\",\"binary-time\"],[8],[0,\"\\n        \"],[1,[28,\"t\",[\"positions.at\"],null],false],[0,\"\\n        \"],[1,[23,1,[]],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"span\",true],[10,\"class\",\"spread-name\"],[8],[0,\"\\n    \"],[1,[28,\"extract-spread-market-name\",null,[[\"instrumentName\"],[[24,[\"instrumentName\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"span\",true],[10,\"class\",\"spread-price\"],[8],[0,\"\\n    \"],[1,[28,\"extract-spread-market-price\",null,[[\"instrumentName\"],[[24,[\"instrumentName\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"showTime\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\",true],[10,\"class\",\"spread-time\"],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"positions.at\"],null],false],[0,\"\\n      \"],[1,[28,\"extract-spread-market-time\",null,[[\"instrumentName\"],[[24,[\"instrumentName\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nadex-web-app/templates/components/formatted-market-name.hbs"
    }
  });
  _exports.default = _default;
});