define("nadex-web-app/components/plaid-error", ["exports", "shared-components/components/plaid-error"], function (_exports, _plaidError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _plaidError.default;
    }
  });
});