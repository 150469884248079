define("nadex-web-app/templates/components/price-buttons", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "v19duigK",
    "block": "{\"symbols\":[\"@instrument\",\"@onOpenTicket\",\"@displayOffer\",\"@displayBid\"],\"statements\":[[7,\"a\",false],[12,\"role\",\"button\"],[12,\"class\",\"price_tick price-button price-button--sell sell\"],[3,\"action\",[[23,0,[]],[23,2,[]],[23,1,[]],\"-\"]],[8],[0,\"\\n  \"],[1,[28,\"market-price\",[[23,3,[]]],null],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"a\",false],[12,\"role\",\"button\"],[12,\"class\",\"price_tick price-button price-button--buy buy\"],[3,\"action\",[[23,0,[]],[23,2,[]],[23,1,[]],\"+\"]],[8],[0,\"\\n  \"],[1,[28,\"market-price\",[[23,4,[]]],null],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nadex-web-app/templates/components/price-buttons.hbs"
    }
  });
  _exports.default = _default;
});