define("nadex-web-app/utils/validate", ["exports", "nadex-web-app/utils/number", "nadex-web-app/utils/closing-price"], function (_exports, _number, _closingPrice) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.closingPriceInRange = closingPriceInRange;
  _exports.hasDirection = hasDirection;
  _exports.priceDivisibleByIncrement = priceDivisibleByIncrement;
  _exports.priceInRange = priceInRange;
  _exports.sizeAboveMin = sizeAboveMin;
  _exports.sizeBelowMax = sizeBelowMax;
  _exports.validate = validate;
  function hasDirection(_ref) {
    var direction = _ref.direction;
    return {
      field: 'direction',
      valid: !!direction,
      error: {
        key: 'errors.direction-invalid'
      }
    };
  }
  function priceInRange(_ref2) {
    var price = _ref2.price,
      _ref2$instrument = _ref2.instrument,
      maxPrice = _ref2$instrument.maxPrice,
      minPrice = _ref2$instrument.minPrice;
    return {
      field: 'price',
      valid: price >= minPrice && price <= maxPrice,
      error: {
        key: 'errors.price-invalid',
        data: {
          maxPrice: maxPrice,
          minPrice: minPrice
        }
      }
    };
  }
  function closingPriceInRange(_ref3) {
    var direction = _ref3.direction,
      _ref3$instrument = _ref3.instrument,
      ceiling = _ref3$instrument.ceiling,
      floor = _ref3$instrument.floor,
      increment = _ref3$instrument.increment,
      price = _ref3.price,
      openLevel = _ref3.position.position.openLevel;
    var min = (0, _closingPrice.minimumClosingPrice)(direction, openLevel, increment, floor);
    var max = (0, _closingPrice.maximumClosingPrice)(direction, openLevel, increment, ceiling);
    return {
      field: 'price',
      valid: price >= min && price <= max,
      error: {
        key: 'errors.price-invalid',
        data: {
          maxPrice: max,
          minPrice: min
        }
      }
    };
  }
  function sizeAboveMin(_ref4) {
    var size = _ref4.size;
    var min = 1;
    return {
      field: 'size',
      valid: +size >= min,
      error: {
        key: 'errors.size-below-min',
        data: {
          min: min
        }
      }
    };
  }
  function sizeBelowMax(_ref5) {
    var size = _ref5.size,
      _ref5$max = _ref5.max,
      max = _ref5$max === void 0 ? 1000 : _ref5$max;
    return {
      field: 'size',
      valid: +size <= max,
      error: {
        key: 'errors.size-above-max',
        data: {
          max: max
        }
      }
    };
  }
  function priceDivisibleByIncrement(_ref6) {
    var price = _ref6.price,
      increment = _ref6.instrument.increment;
    return {
      field: 'price',
      valid: (0, _number.isValidIncrement)(+price, +increment),
      error: {
        key: 'errors.price-invalid-increment',
        data: {
          increment: increment
        }
      }
    };
  }
  function validate(intl, submitData) {
    var validators = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [hasDirection, priceInRange, sizeAboveMin, sizeBelowMax, priceDivisibleByIncrement];
    return validators.reduce(function (errors, validator) {
      var result = validator(submitData);
      if (!result.valid) {
        errors[result.field] = intl.t(result.error.key, result.error.data);
      }
      return errors;
    }, {});
  }
});