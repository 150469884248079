define("nadex-web-app/templates/account/alerts/balances/chart-epic-unselected", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "//o8e0yj",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\",true],[10,\"class\",\"page chart\"],[8],[0,\"\\n  \"],[5,\"chart-view\",[],[[\"@chartApi\",\"@session\",\"@hierarchyId\",\"@isBracket\",\"@backAction\",\"@openTicket\"],[[24,[\"model\",\"chartApi\"]],[24,[\"model\",\"session\"]],[24,[\"model\",\"hierarchyId\"]],[28,\"is-bracket\",[[24,[\"model\",\"instruments\",\"firstObject\"]]],null],[28,\"route-action\",[\"back\"],null],[28,\"route-action\",[\"openTicket\"],null]]]],[0,\"\\n\"],[9],[0,\"\\n\"],[1,[22,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nadex-web-app/templates/account/alerts/balances/chart-epic-unselected.hbs"
    }
  });
  _exports.default = _default;
});