define("nadex-web-app/helpers/extract-binary-market-price", ["exports", "nadex-web-app/utils/instrument-name-to-details"], function (_exports, _instrumentNameToDetails) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.extractBinaryMarketPrice = extractBinaryMarketPrice;
  function extractBinaryMarketPrice(params, _ref) {
    var instrumentName = _ref.instrumentName,
      showSymbol = _ref.showSymbol;
    var _getBinaryPriceAndTim = (0, _instrumentNameToDetails.getBinaryPriceAndTime)(instrumentName, showSymbol),
      price = _getBinaryPriceAndTim.price;
    return price;
  }
  var _default = Ember.Helper.helper(extractBinaryMarketPrice);
  _exports.default = _default;
});