define("nadex-web-app/templates/components/mini-chart-legend", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "IIgHjPc0",
    "block": "{\"symbols\":[\"@expiry\",\"@from\",\"&default\"],\"statements\":[[7,\"text\",true],[10,\"class\",\"mini-chart_time-frame\"],[10,\"x\",\"4\"],[10,\"y\",\"100%\"],[10,\"dy\",\"-2\"],[8],[0,\"\\n  \"],[1,[23,2,[]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"text\",true],[10,\"class\",\"mini-chart_expiry\"],[10,\"x\",\"50%\"],[10,\"y\",\"100%\"],[10,\"dy\",\"-2\"],[10,\"text-anchor\",\"middle\"],[8],[0,\"\\n\"],[4,\"if\",[[23,1,[]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"t\",[\"ticket.expires\"],null],false],[0,\" \"],[1,[23,1,[]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[28,\"t\",[\"positions.expired\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"],[7,\"text\",true],[10,\"class\",\"mini-chart_indicative\"],[10,\"x\",\"100%\"],[10,\"y\",\"100%\"],[10,\"dy\",\"-2\"],[10,\"text-anchor\",\"end\"],[8],[0,\"\\n  \"],[1,[28,\"t\",[\"positions.indicative\"],null],false],[0,\"\\n\"],[9],[0,\"\\n\"],[14,3]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nadex-web-app/templates/components/mini-chart-legend.hbs"
    }
  });
  _exports.default = _default;
});