define("nadex-web-app/utils/market-is-closed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.marketIsClosed = marketIsClosed;
  function marketIsClosed(instrument) {
    var status = Ember.get(instrument, 'updates.marketStatus.value');
    var MARKET_IS_OPEN_VALUES = [2, 6];
    return !MARKET_IS_OPEN_VALUES.includes(+status);
  }
});