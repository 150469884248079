define("nadex-web-app/templates/not-found", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "p5bPxDvM",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"page_sub error\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"error_message\"],[8],[0,\"\\n    \"],[7,\"h2\",true],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"error.not-found-header\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[5,\"link-to\",[],[[\"@route\"],[\"account\"]],{\"statements\":[[0,\"\\n      \"],[1,[28,\"t\",[\"error.go-back\"],null],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nadex-web-app/templates/not-found.hbs"
    }
  });
  _exports.default = _default;
});