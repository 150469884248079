define("nadex-web-app/utils/closing-price", ["exports", "nadex-web-app/helpers/calculate-profit-loss", "lodash/round"], function (_exports, _calculateProfitLoss, _round) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getInitialClosingPrice = getInitialClosingPrice;
  _exports.maximumClosingPrice = maximumClosingPrice;
  _exports.minimumClosingPrice = minimumClosingPrice;
  function minimumClosingPrice(direction, averagePrice, increment, floor) {
    if (!increment) {
      return null;
    }
    var scalingFactor = 1 / increment;
    var minPrice = direction === '+' ? floor + increment : averagePrice + increment;

    // ensure minPrice is always a multiple of increment
    return direction === '-' ? Math.ceil(minPrice * scalingFactor) / scalingFactor : Math.floor(minPrice * scalingFactor) / scalingFactor;
  }
  function maximumClosingPrice(direction, averagePrice, increment, ceiling) {
    if (!increment) {
      return null;
    }
    var scalingFactor = 1 / increment;
    var maxPrice = direction === '+' ? averagePrice - increment : ceiling - increment;

    // ensure maxPrice is always a multiple of increment
    return direction === '+' ? Math.ceil(maxPrice * scalingFactor) / scalingFactor : Math.floor(maxPrice * scalingFactor) / scalingFactor;
  }
  function getInitialClosingPrice(position) {
    var currentPrice = position.position.direction === '+' ? +position.market.updates.displayOffer.value : +position.market.updates.displayBid.value;
    var profitLoss = (0, _calculateProfitLoss.calculateProfitLoss)([position.position.direction, position.market.lotSize, position.market.tickIncrement ? position.market.tickIncrement : position.market.tickMatrixData.rangeData[0].increment, position.position.dealSize, position.position.openLevel, currentPrice]);
    if ((0, _round.default)(profitLoss, 2) > 0) {
      return currentPrice;
    }
    return position.position.openLevel;
  }
});