define("nadex-web-app/routes/account/alerts/balances/chart/ticket-loading", ["exports", "nadex-web-app/routes/loading"], function (_exports, _loading) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _loading.default;
    }
  });
});