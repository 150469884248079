define("nadex-web-app/templates/account/alerts/balances/chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "HOxHiGrY",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\",true],[10,\"class\",\"page chart\"],[8],[0,\"\\n  \"],[5,\"chart-view\",[],[[\"@chartApi\",\"@session\",\"@hierarchyId\",\"@epic\",\"@direction\",\"@isBracket\",\"@lastTradeDateTime\",\"@backAction\",\"@openTicket\"],[[24,[\"model\",\"chartApi\"]],[24,[\"model\",\"session\"]],[24,[\"model\",\"hierarchyId\"]],[24,[\"model\",\"selectedInstrument\",\"epic\"]],[24,[\"model\",\"selectedInstrument\",\"direction\"]],[28,\"is-bracket\",[[24,[\"model\",\"selectedInstrument\"]]],null],[24,[\"model\",\"selectedInstrument\",\"lastTradeDateTime\"]],[28,\"route-action\",[\"back\"],null],[28,\"route-action\",[\"openTicket\"],null]]]],[0,\"\\n\"],[9],[0,\"\\n\"],[1,[22,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nadex-web-app/templates/account/alerts/balances/chart.hbs"
    }
  });
  _exports.default = _default;
});