define("nadex-web-app/helpers/extract-jwt", ["exports", "shared-components/helpers/extract-jwt"], function (_exports, _extractJwt) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _extractJwt.default;
    }
  });
  Object.defineProperty(_exports, "extractJwt", {
    enumerable: true,
    get: function get() {
      return _extractJwt.extractJwt;
    }
  });
});