define("nadex-web-app/utils/user-mode", ["exports", "nadex-web-app/utils/device-type"], function (_exports, _deviceType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.USAGE_TYPES = void 0;
  _exports.default = getMode;
  var USAGE_TYPES = {
    ON_HOME_SCREEN: 'ON_HOME_SCREEN',
    BROWSER: 'BROWSER',
    CANNOT_DETERMINE: 'CANNOT_DETERMINE'
  };
  _exports.USAGE_TYPES = USAGE_TYPES;
  /* istanbul ignore next */
  function getUsageApple() {
    return navigator.standalone ? USAGE_TYPES.ON_HOME_SCREEN : USAGE_TYPES.BROWSER;
  }

  /* istanbul ignore next */
  function usageModeFromDisplayMode() {
    if (window.matchMedia) {
      var isStandAlone = window.matchMedia('(display-mode: standalone)').matches;
      var isBrowser = window.matchMedia('(display-mode: browser)').matches;

      // If these are the same then display mode is not supported
      // cannot determine the launch site
      if (isStandAlone != isBrowser) {
        return isStandAlone ? USAGE_TYPES.ON_HOME_SCREEN : USAGE_TYPES.BROWSER;
      }
    }
    return USAGE_TYPES.CANNOT_DETERMINE;
  }
  function getMode() {
    var platform = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : navigator.platform;
    if ((0, _deviceType.isApple)(platform)) {
      return getUsageApple();
    } else {
      return usageModeFromDisplayMode();
    }
  }
});