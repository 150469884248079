define("nadex-web-app/templates/components/numeric-incrementors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "q8KQeffV",
    "block": "{\"symbols\":[\"@disabled\",\"&default\"],\"statements\":[[5,\"pulse-button\",[[12,\"class\",\"ticket_pulse ticket_pulse--right\"]],[[\"@press\",\"@release\",\"@disabled\"],[[28,\"perform\",[[24,[\"adjust\"]],\"increment\"],null],[28,\"cancel-all\",[[24,[\"adjust\"]]],null],[28,\"or\",[[23,1,[]],[23,0,[\"increaseDisabled\"]]],null]]],{\"statements\":[[0,\"\\n  \"],[7,\"svg\",true],[10,\"viewBox\",\"0 0 26 26\"],[10,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[8],[0,\"\\n    \"],[7,\"rect\",true],[10,\"stroke-width\",\"1.5\"],[10,\"transform\",\"rotate(-180 13 13)\"],[10,\"x\",\".75\"],[10,\"y\",\".75\"],[10,\"width\",\"24.5\"],[10,\"height\",\"24.5\"],[10,\"rx\",\"4\"],[8],[9],[0,\"\\n    \"],[7,\"g\",true],[10,\"stroke-linecap\",\"round\"],[10,\"stroke-width\",\"2\"],[8],[0,\"\\n      \"],[7,\"path\",true],[10,\"d\",\"M6.333 13h13.334M13 6.333v13.334\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[14,2,[[24,[\"adjust\"]],[24,[\"displayValue\"]],[24,[\"max\"]],[24,[\"min\"]]]],[0,\"\\n\"],[5,\"pulse-button\",[[12,\"class\",\"ticket_pulse ticket_pulse--left\"]],[[\"@press\",\"@disabled\",\"@release\"],[[28,\"perform\",[[24,[\"adjust\"]],\"decrement\"],null],[28,\"or\",[[23,1,[]],[23,0,[\"decreaseDisabled\"]]],null],[28,\"cancel-all\",[[24,[\"adjust\"]]],null]]],{\"statements\":[[0,\"\\n  \"],[7,\"svg\",true],[10,\"viewBox\",\"0 0 26 26\"],[10,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[8],[0,\"\\n    \"],[7,\"rect\",true],[10,\"stroke-width\",\"1.5\"],[10,\"x\",\".75\"],[10,\"y\",\".75\"],[10,\"width\",\"24.5\"],[10,\"height\",\"24.5\"],[10,\"rx\",\"4\"],[8],[9],[0,\"\\n    \"],[7,\"path\",true],[10,\"d\",\"M6.333 13h13.334\"],[10,\"stroke-linecap\",\"round\"],[10,\"stroke-width\",\"2\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nadex-web-app/templates/components/numeric-incrementors.hbs"
    }
  });
  _exports.default = _default;
});