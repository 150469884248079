define("nadex-web-app/templates/account/alerts/balances/trades/orders", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "hyns2WCV",
    "block": "{\"symbols\":[\"underlyings\",\"instrumentType\",\"orders\"],\"statements\":[[7,\"section\",true],[10,\"class\",\"page page--stay card-holder order\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"model\",\"orders\"]]],null,{\"statements\":[[0,\"    \"],[5,\"indicative-prices\",[],[[\"@epics\"],[[28,\"map-by\",[\"underlyingEpic\",[28,\"map-by\",[\"market\",[24,[\"model\",\"orders\"]]],null]],null]]],{\"statements\":[[0,\"\\n      \"],[5,\"group-by-instrument-type\",[],[[\"@trades\"],[[24,[\"model\",\"orders\"]]]],{\"statements\":[[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"order_sub-header\"],[8],[0,\"\\n          \"],[1,[28,\"t\",[[28,\"concat\",[\"positions.\",[28,\"lowercase\",[[23,2,[]]],null]],null]],null],false],[0,\"\\n        \"],[9],[0,\"\\n        \"],[5,\"orders-list\",[],[[\"@lightstreamer\",\"@onEditOrder\",\"@orders\",\"@underlyings\"],[[24,[\"model\",\"lightstreamer\"]],[28,\"route-action\",[\"editOrder\"],null],[28,\"sort-by\",[[28,\"route-action\",[\"sortTrades\"],null],[23,3,[]]],null],[23,1,[]]]]],[0,\"\\n      \"]],\"parameters\":[2,3]}],[0,\"\\n    \"]],\"parameters\":[1]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[5,\"empty-monitor\",[],[[\"@monitorType\"],[\"orders\"]]],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"],[1,[22,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nadex-web-app/templates/account/alerts/balances/trades/orders.hbs"
    }
  });
  _exports.default = _default;
});