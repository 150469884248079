define("nadex-web-app/templates/components/account-type-badge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "8U/zXu6S",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"isEntity\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"t\",[\"account.entity\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"isEntityTrader\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"t\",[\"account.entity-trader\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"isIndividualLive\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"t\",[\"account.live\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"isIndividualDemo\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"t\",[\"account.demo\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nadex-web-app/templates/components/account-type-badge.hbs"
    }
  });
  _exports.default = _default;
});