define("nadex-web-app/utils/platform-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getPlatformStatus;
  function getPlatformStatus(statusData, env) {
    var componentName = env === 'DEMO' ? 'demo web trading platform' : 'live web trading platform';
    if (!statusData.components) {
      return null;
    }
    var wtpComponent = statusData.components.find(function (component) {
      return component.name.toLowerCase() === componentName;
    });
    if (!wtpComponent) {
      return null;
    }
    return wtpComponent.status;
  }
});