define("nadex-web-app/templates/account/alerts/tax-statement", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "CKErMWdW",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"page-stack contact\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"page card-holder\"],[8],[0,\"\\n    \"],[5,\"link-to\",[[12,\"class\",\"page_back\"]],[[\"@route\"],[\"account.alerts.balances.profile\"]],{\"statements\":[[0,\"\\n      \"],[1,[28,\"t\",[\"profile.account\"],null],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n    \"],[5,\"tax-statement-card\",[],[[],[]]],[0,\"\\n    \"],[1,[22,\"outlet\"],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nadex-web-app/templates/account/alerts/tax-statement.hbs"
    }
  });
  _exports.default = _default;
});