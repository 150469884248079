define("nadex-web-app/utils/direction-word-to-symbol", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = wordToSymbol;
  /**
   * When passed ticket direction in word format, returns the symbol equivalent.
   * '+' returns 'buy', '-' returns 'sell'.
   * Otherwise returns null, this case is necessary for directionless tickets.
   * @param {String|null} direction
   */
  function wordToSymbol(direction) {
    if (direction === 'buy') {
      return '+';
    } else if (direction === 'sell') {
      return '-';
    } else {
      return null;
    }
  }
});