define("nadex-web-app/templates/account/alerts/statement", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "nYAlFdCC",
    "block": "{\"symbols\":[\"fetchStatement\",\"pdfDocument\",\"renderPage\",\"_\",\"index\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"page-stack contact\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"page card-holder\"],[8],[0,\"\\n    \"],[5,\"link-to\",[[12,\"class\",\"page_back\"]],[[\"@route\"],[\"account.alerts.balances.profile\"]],{\"statements\":[[0,\"\\n      \"],[1,[28,\"t\",[\"profile.account\"],null],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"],[4,\"let\",[[28,\"route-task\",[\"fetchStatement\"],null]],null,{\"statements\":[[0,\"      \"],[5,\"statement-card\",[],[[\"@downloadStatement\",\"@fetchStatement\",\"@maxScale\"],[[28,\"task\",[[24,[\"model\",\"statementService\",\"downloadStatement\"]]],null],[23,1,[]],\"2\"]],{\"statements\":[[0,\"\\n\"],[0,\"        \"],[7,\"pinch-zoom\",false],[12,\"class\",\"statement_document-zoom\"],[3,\"on\",[\"change\",[28,\"route-action\",[\"constrainPan\"],null]]],[8],[0,\"\\n          \"],[7,\"div\",true],[8],[0,\"\\n\"],[4,\"each\",[[28,\"repeat\",[[23,1,[\"last\",\"value\",\"pdfDocument\",\"numPages\"]]],null]],null,{\"statements\":[[0,\"              \"],[7,\"canvas\",false],[12,\"class\",\"statement_document\"],[3,\"did-insert\",[[28,\"perform\",[[23,3,[]],[23,2,[]],[23,5,[]]],null]]],[8],[9],[0,\"\\n\"]],\"parameters\":[4,5]},null],[0,\"          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n\"],[0,\"      \"]],\"parameters\":[2,3]}],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nadex-web-app/templates/account/alerts/statement.hbs"
    }
  });
  _exports.default = _default;
});