define("nadex-web-app/templates/account/alerts/balances/trades/positions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "u9AfkB+y",
    "block": "{\"symbols\":[\"underlyings\",\"instrumentType\",\"positions\"],\"statements\":[[7,\"section\",true],[10,\"class\",\"page page--stay card-holder position\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"model\",\"positions\"]]],null,{\"statements\":[[0,\"    \"],[5,\"indicative-prices\",[],[[\"@epics\"],[[28,\"map-by\",[\"underlyingEpic\",[28,\"map-by\",[\"market\",[24,[\"model\",\"positions\"]]],null]],null]]],{\"statements\":[[0,\"\\n      \"],[5,\"group-by-instrument-type\",[],[[\"@trades\"],[[24,[\"model\",\"positions\"]]]],{\"statements\":[[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"position_sub-header\"],[8],[0,\"\\n          \"],[1,[28,\"t\",[[28,\"concat\",[\"positions.\",[28,\"lowercase\",[[23,2,[]]],null]],null]],null],false],[0,\"\\n        \"],[9],[0,\"\\n        \"],[5,\"positions-list\",[],[[\"@positions\",\"@underlyings\",\"@lightstreamer\",\"@launchTicketFromPositions\",\"@openCloseDealTicket\"],[[28,\"sort-by\",[[28,\"route-action\",[\"sortTrades\"],null],[23,3,[]]],null],[23,1,[]],[24,[\"model\",\"lightstreamer\"]],[28,\"route-action\",[\"openPositionTicket\"],null],[28,\"route-action\",[\"openCloseDealTicket\"],null]]]],[0,\"\\n      \"]],\"parameters\":[2,3]}],[0,\"\\n    \"]],\"parameters\":[1]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[5,\"empty-monitor\",[],[[\"@monitorType\"],[\"positions\"]]],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"],[1,[22,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nadex-web-app/templates/account/alerts/balances/trades/positions.hbs"
    }
  });
  _exports.default = _default;
});