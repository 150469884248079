define("nadex-web-app/components/accordion-group", ["exports", "ember-diff-attrs"], function (_exports, _emberDiffAttrs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['accordion-group'],
    _expandingItem: null,
    _collapsingItem: null,
    didReceiveAttrs: (0, _emberDiffAttrs.default)('expandedItem', function (changedAttrs) {
      for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }
      this._super.apply(this, args);
      if (changedAttrs && !changedAttrs.expandedItem) {
        return;
      }
      Ember.setProperties(this, {
        _collapsingItem: this._expandingItem,
        _expandingItem: this.expandedItem
      });
    }),
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      Ember.setProperties(this, {
        _collapsingItem: null,
        _expandingItem: null
      });
    }
  });
  _exports.default = _default;
});